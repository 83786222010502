import React from 'react'
import CompanyLogo from "../../assets/VEDHAS AI/02 (2).png";
import { Link } from 'react-router-dom';

export default function PrivacyPolicyComponent() {
    return (
        <div className='TermsAndConditions_PrivacyPolicy_Section'>
            <div className="container">
                <h1 title='Privacy & Policy'>Privacy &  Policy</h1>
                <div className="TermsAndConditions_PrivacyPolicy_info">
                    <p>
                        This website is established and operated by{" "}
                        <a rel='noopener noreferrer' title='Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com/">
                            Vedhas AI Technology
                        </a>
                        , with its office located at Gujrat, India, represented by its
                        Directors, hereinafter referred to as the Company (where such
                        expression shall, unless repugnant to the context thereof, be deemed
                        to include its respective legal heirs, representatives,
                        administrators, permitted successors, and assigns).
                    </p>
                    <p>
                        This legal agreement constitutes an electronic record in compliance
                        with the <b title='Indian Information Technology Act, 2000'>Indian Information Technology Act, 2000,</b> and its
                        relevant regulations, including amendments related to electronic
                        records. This electronic document is generated through computer
                        systems and does not necessitate physical or digital signatures.
                    </p>
                    <p>
                        This legal document is made publicly available in adherence to{" "}
                        <b title='Rule 3(1) of the Indian Information Technology'>Rule 3(1) of the Indian Information Technology</b>{" "}
                        (Intermediaries guidelines) Rules, 2011, and Rule 4 of the
                        Information Technology (Reasonable security practices and procedures
                        and sensitive personal data or information) Rules, 2011, as amended
                        by the Indian Information Technology Amendment Act, 2008. These
                        rules mandate the publication of the Terms of Services and practices
                        governing access to or usage of the website/platform, which is{" "}
                        <a rel='noopener noreferrer' title='www.vedhasaitech.com' target="_blank" href="https://vedhasaitech.com/">
                            www.vedhasaitech.com
                        </a>
                        , with its office located website/platform.
                    </p>
                    <p>
                        The creator of this Privacy Policy is committed to safeguarding your
                        privacy and the protection of your confidential information shared
                        on this platform. This privacy policy pertains to the website
                        <a rel='noopener noreferrer' title='www.vedhasaitech.com' target="_blank" href="https://vedhasaitech.com/">
                            www.vedhasaitech.com
                        </a>
                        , with its office located , collectively referred to as the{" "}
                        <b title='Platform'>Platform</b>.
                    </p>
                    <p>
                        To ensure a seamless experience while using the Application, we may
                        collect and, under specific circumstances, disclose information
                        about you with your consent. In order to enhance the security of
                        your privacy, we provide this notice elucidating our information
                        collection and disclosure practices, as well as the choices you have
                        regarding how your information is collected and utilized.
                    </p>
                    <p>
                        This Privacy Policy is fully compliant with the General Data
                        <b title='Protection Regulation (GDPR) effective from May 25, 2018'>Protection Regulation (GDPR) effective from May 25, 2018.</b> Any
                        provisions that may appear to contradict GDPR regulations shall be
                        considered null and unenforceable as of that date. If you do not
                        agree with the terms and conditions outlined in our Privacy Policy,
                        particularly concerning the collection and use of your information,
                        we kindly request that you refrain from using or accessing the Site.
                        For any inquiries or concerns related to this Privacy Policy, please
                        feel free to reach out to our Customer Support Desk at
                        <b title='info@vedhasaitech.com'>info@vedhasaitech.com</b>
                    </p>
                    <p>
                        All capitalized terms used from this point onward shall hold the
                        definitions ascribed to them within this Agreement. Furthermore, any
                        headings employed herein are solely for the purpose of organizing
                        the various provisions of this Agreement and should not be used by
                        either the user or the creators of this{" "}
                        <b>
                            Privacy Policy to interpret the contents of this document in any
                            manner.
                        </b>
                    </p>
                    <h2 title='Data Collection'>1.Data Collection</h2>
                    <p>
                        At{" "}
                        <a rel='noopener noreferrer' title=' Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com">
                            Vedhas AI Technology
                        </a>
                        , safeguarding your online privacy is our utmost priority, and we
                        are dedicated to responsibly managing the Personal Information you
                        entrust to us. In line with this commitment, we outline the types of
                        information we may collect:
                    </p>
                    <p>
                        Personal Data: This includes, but is not limited to, details like
                        your Username, Password, Name, Phone Number, Email Address, and bank
                        account information.
                    </p>
                    <p>
                        Tracking Information: We may gather data such as your device's IP
                        address and Device ID when connected to the Internet. This
                        information encompasses the URL you visited before and after using
                        our platform, <b title="your device's browser information">your device's browser information</b>, and other
                        interactions you have with our platform.
                    </p>
                    <p>
                        Platform Usage Details: We collect information related to your usage
                        of our platform for analytics purposes.
                    </p>
                    <p>
                        It's essential to note that this <b title='privacy policy'>privacy policy</b> extends to
                        all users, whether registered members or visitors to our platform.
                        We may also collect and store information provided by you on our
                        platform to ensure a seamless and secure experience tailored to your
                        preferences. This includes:
                    </p>
                    <ul>
                        <li>Facilitating the services you've requested.</li>
                        <li>Customizing content to align with your interests.</li>
                        <li>
                            Communicating important account and service-related updates.
                        </li>
                        <li>Providing top-notch customer support and data collection.</li>
                        <li>Complying with relevant laws, rules, and regulations.</li>
                    </ul>
                    <p>
                        In cases where a third party is involved in providing a service
                        you've requested, we may share the necessary information with them.
                        Additionally, we use your contact information to send you offers
                        based on your interests and previous interactions, as well as to
                        curate content that suits your preferences. Any contact information
                        used for internal purposes to enhance our services is promptly
                        deleted upon your withdrawal of consent, either through the
                        'unsubscribe' button or by contacting us at <b title='info@vedhasaitech.com
                        '>info@vedhasaitech.com</b>
                    </p>
                    <p>
                        We respect your choices regarding the information you share with us.
                        You have the option not to disclose specific information you prefer
                        we do not collect, store, or use. You can also opt out of
                        non-essential communications from our platform by choosing not to
                        use certain services or by adjusting your communication preferences.
                    </p>
                    <p>
                        Furthermore, it's crucial to acknowledge the inherent risks of
                        online transactions. To enhance your online security, we advise you
                        to follow best practices, such as not sharing account/login
                        information with others and promptly reporting any suspicious
                        activity or potential compromises to our customer care team.
                    </p>
                    <p>
                        To ensure uninterrupted use of our <b title='application'>application</b> and to protect
                        your privacy, we may collect and, with your consent, disclose
                        certain information about you. This notice clarifies our policies
                        regarding information collection and disclosure, empowering you to
                        make informed decisions about how your data is collected and
                        utilized. Your privacy matters to us, and we are committed to
                        maintaining its integrity.
                    </p>
                    <h2 title='Our Use of Your Information'>2.Our Use of Your Information</h2>
                    <p>
                        The information you provide will be utilized to enhance and improve
                        our services for both you and all our users. This includes:
                    </p>
                    <ul>
                        <li>Providing services upon your request.</li>
                        <li>Maintaining internal records.</li>
                        <li>Enhancing the services we offer.</li>
                        <li>Complying with legal and statutory provisions.</li>
                    </ul>
                    <p>
                        For more details regarding the nature of these communications,
                        please refer to our Terms of Service. Additionally, your data and
                        Sensitive Personal{" "}
                        <b>
                            Information may be collected and stored by us for internal
                            records.
                        </b>
                    </p>
                    <p>
                        We utilize tracking information such as IP addresses and Device IDs
                        to identify you and gather general demographic data. This enables us
                        to provide you with more <b title='tailored'>tailored</b> services.
                    </p>
                    <p>
                        To better serve our users and enhance their website experience, we
                        may share specific user data with affiliated companies that have
                        referred users to the{" "}
                        <a rel='noopener noreferrer' title=' Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com">
                            Vedhas AI Technology
                        </a>{" "}
                        AI website. These affiliates are third-party entities with a
                        business relationship with{" "}
                        <a rel='noopener noreferrer' title=' Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com">
                            Vedhas AI Technology.
                        </a>
                        Data sharing only occurs with companies that have entered into a
                        data sharing agreement with
                        <a rel='noopener noreferrer' title=' Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com">
                            Vedhas AI Technology
                        </a>
                        , and solely for the purpose of improving user service.
                    </p>
                    <p>
                        We do not sell, license, or trade your personal information. Your
                        personal information will only be shared with others if they are
                        acting under our instructions or if required by law.
                    </p>
                    <p>
                        Information collected through our server logs includes user IP
                        addresses and visited pages. This data helps manage our web system
                        and troubleshoot issues. Additionally, we utilize third-party
                        analytics, tracking, optimization, and targeting tools to understand
                        user engagement with our platform, allowing us to enhance it and
                        provide personalized content and ads based on user preferences.
                    </p>
                    <h2 title='How Information Is Collected'>3.How Information Is Collected</h2>
                    <p>
                        Before or at the time of <b title='collecting'>collecting</b> personal information, we
                        will clearly state the purposes for which the information is being
                        collected. If the purpose is not identified, you have the right to
                        request clarification from the company before disclosing any
                        information.
                    </p>
                    <p>
                        We collect and use your personal information exclusively for the
                        purposes specified by us, within the scope of individual consent or
                        as required by law. Personal information is retained only for as
                        long as necessary to fulfill these purposes. We gather personal
                        information through lawful and fair means, with the knowledge and
                        consent of the individuals involved.
                    </p>
                    <p>
                        Personal data is kept relevant to its intended use and is maintained
                        in an accurate, complete, and up-to-date manner.
                    </p>
                    <h2 title='External Links on the Platform'>4.External Links on the Platform</h2>
                    <p>
                        The Platform may contain <b title='advertisements'>advertisements</b> and hyperlinks to
                        other websites or resources. We have no control over these external
                        websites, resources, or their contents, as they are provided by
                        entities other than us. You acknowledge and agree that we are not
                        responsible for the availability of such external sites or resources
                        and do not endorse any advertising, services, or materials on or
                        available from these sources.
                    </p>
                    <p>
                        You also <b title='acknowledge'>acknowledge</b> and agree that we are not liable for any
                        loss or damage you may incur due to the availability of external
                        sites or resources or due to your reliance on the completeness,
                        accuracy, or existence of any advertising, services, or materials on
                        these websites. These external third-party websites and resource
                        providers may have their privacy policies governing the collection,
                        storage, retention, and disclosure of your personal information. We
                        recommend reviewing their privacy policies upon visiting their
                        websites.
                    </p>
                    <h2 title='Cookies'>5.Cookies</h2>
                    <p>
                        We employ data collection devices such as cookies on specific pages
                        of our Platform. These cookies are small files that reside on your
                        hard drive and aid us in delivering personalized services. Some
                        features on our Platform are accessible only through the use of
                        these cookies. Cookies are also instrumental in tailoring
                        information to match your interests and may be used to identify
                        logged-in or registered users. Our Platform utilizes session cookies
                        to ensure a seamless user experience. These cookies contain a unique
                        identifier, your 'session ID,' enabling our server to recognize your
                        device and recall your actions on the site. This offers several
                        advantages:
                    </p>
                    <p>
                        You only need to log in once when navigating secure areas of the
                        site.
                    </p>
                    <p>
                        Our server can differentiate your device from others, ensuring you
                        receive the requested information.
                    </p>
                    <p>
                        You have the option to accept or decline cookies by adjusting your
                        browser settings. However, choosing to disable cookies may limit
                        your ability to fully utilize our Platform. Additionally, we employ
                        various third-party cookies for collecting usage, behavioral,
                        analytics, and preference data. These are the different types of
                        <b title='cookies'>cookies</b> used on our Platform:
                    </p>
                    <p>
                        Authentication cookies: These identify the user and provide
                        requested content.
                    </p>
                    <p>
                        Functionality cookies: Enhance user experiences and remember course
                        progress.
                    </p>
                    <p>
                        Tracking, optimization, and targeting cookies: Capture usage metrics
                        and behavioral data for improved content delivery, as well as
                        suggest suitable services.
                    </p>
                    <h2 title='Your Rights'>6.Your Rights</h2>
                    <p>
                        Unless exempt, you possess the following rights regarding your data:
                    </p>
                    <ul>
                        <li>The right to request a copy of your data that we hold.</li>
                        <li>
                            The right to request correction of inaccurate or outdated personal
                            data.
                        </li>
                        <li>
                            The right to withdraw consent for data processing at any time.
                        </li>
                        <li>The right to object to personal data processing.</li>
                        <li>The right to file a complaint with a supervisory authority.</li>
                        <li>
                            The right to be informed if personal data is transferred to a
                            third country or international organization.
                        </li>
                        <li>
                            If you have an account with our services, you are entitled to a
                            copy of all personal data we hold about you. You can also request
                            restrictions on how we use your data when logged in.
                        </li>
                    </ul>
                    <h2 title='Confidentiality'>7.Confidentiality</h2>
                    <p>
                        You acknowledge that our Platform may contain confidential
                        information. You must not disclose such information without our
                        prior written consent. Your information is treated as confidential
                        and will not be shared with third parties unless legally required.
                        We will not sell, share, or rent your personal information for
                        unsolicited <b title='communication'>communication</b>. Any emails sent by us will be
                        related to agreed-upon services, and you can opt out of such
                        communication at any time.
                    </p>
                    <h2 title='Other Information Collectors'>8.Other Information Collectors</h2>
                    <p>
                        This Privacy Policy addresses our use and disclosure of information
                        collected from you. Different rules may apply when you disclose
                        information to other parties, whether on our Platform or other
                        websites. Third-party advertisers have their privacy policies, and
                        we do not control them. Please inquire before sharing personal
                        information with others.
                    </p>
                    <h2 title='Our Disclosure of Your Information'>9.Our Disclosure of Your Information</h2>
                    <p>
                        We may host surveys for survey creators on our platform, who own and
                        use your survey responses. We do not own or sell your responses.
                        Anything you disclose in your responses will be shared with survey
                        creators. <b title='Information'>Information</b> is not considered sensitive if it is
                        publicly available or provided under applicable laws.
                    </p>
                    <p>
                        Due to regulatory constraints, we cannot guarantee the absolute
                        privacy of your communications and personally identifiable
                        information. We may be obligated to disclose information to
                        government authorities, law enforcement agencies, or third parties.
                        However, we will personally notify you of any such disclosure via
                        email.
                    </p>
                    <p>
                        As a policy, we do not sell or rent your personally identifiable
                        information to third parties. However, some personally identifiable
                        information may be disclosed in specific situations:
                    </p>
                    <p>
                        External Service Providers: Optional services on our Platform may be
                        offered by external service providers. Their use of your information
                        is governed by their privacy policies.
                    </p>
                    <p>
                        Law and Order: We cooperate with law enforcement and other
                        authorities when necessary, disclosing information as required by
                        law.
                    </p>
                    <h2 title='Behavioral Marketing'>9.Behavioral Marketing</h2>
                    <p>
                        <a rel='noopener noreferrer' title=' Vedhas AI Technology' target="_blank" href="https://vedhasaitech.com">
                            Vedhas AI Technology
                        </a>{" "}
                        uses remarketing services to display targeted ads after you visit
                        our Service. We and our third-party vendors utilize cookies and
                        non-cookie technologies to understand your Device and improve our
                        Service. These third-party vendors may collect, store, and use
                        information about your activity on our Service. You can opt out of
                        personalized advertising through various tools and settings on your
                        devices.
                    </p>
                    <h2 title='Accessing, Reviewing, and Changing Your Profile'>10.Accessing, Reviewing, and Changing Your Profile</h2>
                    <p>
                        <b title='Following registration'>Following registration</b>, you can review and modify the
                        information you provided during registration, except for Email ID
                        and mobile number. If you change any information, we may or may not
                        retain your old information. Information you request to remove will
                        be permanently deleted from our databases.
                    </p>
                    <h2 title='Control of Your Password'>11.Control of Your Password</h2>
                    <p>
                        <b title='You are responsible for safeguarding'>You are responsible for safeguarding</b> your password to prevent
                        unauthorized access to your account and information. Do not disclose
                        your password to third parties. If your password is compromised,
                        <b title='change it immediately'> change it immediately.</b>
                    </p>
                    <h2 title='Security'>12.Security</h2>
                    <p>
                        We take data protection seriously, using various security measures
                        to prevent unauthorized access. While we employ industry-standard
                        practices, no security system is infallible. We cannot guarantee the
                        absolute security of our database or information transmitted over
                        the Internet.
                    </p>
                    <h2 title='Severability'>13.Severability</h2>
                    <p>
                        Each paragraph in this <b title='Privacy Policy'>Privacy Policy</b> is <b title='independent'>independent</b>{" "}
                        and severable. Nullifying one or more paragraphs will not affect the
                        others unless expressly indicated otherwise.
                    </p>
                    <h2 title='Amendment'>14.Amendment</h2>
                    <p>
                        Our Privacy Policy may change over time. The most current version is
                        always available on our Platform, and your continued use{" "}
                        <b title='signifies acceptance of any amendments'>signifies acceptance of any amendments.</b>
                    </p>
                    <h2 title='Consent Withdrawal, Data Download & Data Removal Requests'>15.Consent Withdrawal, Data Download & Data Removal Requests</h2>
                    <p>
                        To withdraw consent or request data download or deletion, please
                        email us at <b title='info@vedhasaitech.com'>info@vedhasaitech.com</b>
                    </p>
                    <h2 title='➤ Contact Us'>➤ Contact Us</h2>
                    <p>
                        If you have questions or concerns about this privacy policy, contact
                        us at <b title='info@vedhasaitech.com'>info@vedhasaitech.com</b>
                    </p>
                    <p>
                        Please note that information on the Platform may not always be
                        accurate and may be for promotional purposes.
                    </p>
                </div>
                <div className="TredMark">
                    <b title='From Vedhas AI Team'>From Vedhas AI Team.</b>
                    <div className="TredMark_logo">
                        <Link to={"/"}>
                            <img
                                src={CompanyLogo}
                                title='Vedhas AI'
                                alt="Vedhas AI"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
