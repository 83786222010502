import React from "react";
import "./mobileAppContent.scss";
import HeroImage from "../../../assets/images/Rectangle blog-details-image.svg";
import CardImage1 from "../../../assets/images/blog-post1.svg";
import CardImage2 from "../../../assets/images/blog-post2.svg";
import CardImage3 from "../../../assets/images/blog-post3.svg";
import { NavLink } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import CommonHead from "../../../common/CommonHead";
export default function MainContent() {
  const Mobile_App = [
    {
      image: CardImage1,
      p: "Unleash the Power of Android",
      desc: "Specialize with Android app development, harnessing the power of Google's versatile platform to create innovative and impactful mobile experiences. Our Android development service is meticulously designed to cater to the diverse needs of businesses, empowering them to reach their audience effectively and efficiently. ",
      read_more: "Read More",
    },
    {
      image: CardImage2,
      p: "Revolutionize with iOS",
      desc: "Particularly with the iOS system which are used by the iPhone, iPad, and other Apple mobile products. There are more than one billion active Apple devices all over the world , as a result, iOS developers are highly in demand, especially for companies that want to capture the consumer market of Apple products users.",
      read_more: "Read More",
    },
    {
      image: CardImage3,
      p: "One  code base,  two  mobile  applications",
      desc: "Flutter is Google’s SDK that lets developers build cross platform apps with a single codebase . With this SDK, you get more for less. One Flutter developer will build a solution for two platforms (IOS and Android).",
      read_more: "Read More",
    },
  ];
  return (
    <>
      <CommonHead Heading="MOBILE APP DEVELOPMENT" Sub="Mobile App Development" />
      <section className="mobile_app_page">
        <div className="container">
          <div className="detail_page_heading">
            <Fade bottom duration={700} delay={400}>
              <h2 title="Interactive Apps that Keep Your Audience Engaged">
                Interactive Apps that Keep Your Audience Engaged
              </h2>
            </Fade>
          </div>
          <Zoom>
            <img src={HeroImage} title="Interactive Apps that Keep Your Audience Engaged" alt="Interactive Apps that Keep Your Audience Engaged" />
          </Zoom>
          <Fade bottom duration={600} delay={400}>
            <p>
              At Vedhas, we provide comprehensive custom mobile app development
              services, helping businesses create and develop mobile apps that
              encourage user engagement and help them establish brand loyalty. We
              follow an elaborate process that includes multiple stages of
              development, testing, deployment, and more. We utilize the latest
              technology in the market to ensure that it keeps up with the dynamic
              nature of technology in your business domain.
            </p>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <p>
              From native Android to iOS apps, Vedhas develops high-functioning
              mobile applications services for businesses, creating the right
              balance between visuals, functionality, and ease of use to ensure
              that end users have phenomenal experiences using them.
            </p>
          </Fade>
          <Fade bottom duration={800} delay={600}>
            <p>
              Our deep understanding of industry trends and our commitment to
              meeting our customer’s needs, allow us to develop world-class mobile
              applications that get users hooked, generate revenue, and stand out
              from the competition. As a team, we work securely within Google
              Play’s open environment on Android for creating mobile apps,
              ensuring the best possible user experience for your customers as
              well as ours.
            </p>
          </Fade>
          <blockquote>
            Also read this article: <a href="/mobileapp" title="Mobile App">Mobile App</a>
          </blockquote>
          <div className="main-grid-mob-app">
            {Mobile_App &&
              Mobile_App?.map((el, i) => {
                return (
                  <div className="sub-mob-app-image" key={i}>
                    <Zoom>
                      <img src={el?.image} title={el?.p} alt="cardimage" />
                    </Zoom>
                    <div className="card_desc">
                      <Fade bottom duration={600} delay={400}>
                        <p title={el?.p}>{el?.p}</p>
                      </Fade>
                      <Fade bottom duration={700} delay={500}>
                        <h4 title={el?.desc}>{el?.desc}</h4>
                      </Fade>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#583FBB" : "#583FBB",
                          textDecoration: "none",
                        })}
                      >
                        <Fade bottom duration={800} delay={600}>
                          <h3 title={el?.read_more}>{el?.read_more}</h3>
                        </Fade>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
          </div>
          <Fade bottom duration={600} delay={400}>
            <p>
              Whether it's creating intuitive user interfaces, integrating
              advanced functionalities, or ensuring seamless performance across
              platforms, our dedicated team is committed to transforming ideas
              into impactful mobile experiences.Our mobile app development service
              encompasses a spectrum of features and capabilities, including:
            </p>
            <ul>
              <li>
                Comprehensive analysis and strategic planning to ensure alignment
                with business objectives.
              </li>
              <li>
                Expertise in native app development for iOS and Android platforms,
                utilizing Swift, Kotlin, and Java.
              </li>
              <li>
                Proficiency in cross-platform frameworks such as React Native and
                Flutter for maximum reach and efficiency.
              </li>
              <li>
                Seamless integration of APIs, third-party services, and backend
                systems to enhance functionality and scalability.
              </li>
              <li>
                Rigorous testing and quality assurance processes to deliver
                robust, bug-free applications.
              </li>
              <li>
                Ongoing maintenance and support to ensure optimal performance and
                adaptability to evolving requirements.
              </li>
            </ul>
            <p>
              With Vedhas AI Technologies, you can embark on a transformative
              journey towards mobile excellence, unlocking new opportunities and
              engaging your audience like never before.
            </p>
          </Fade>
        </div>
      </section>
    </>
  );
}
