import "./scss/global.scss";
import { RouterProvider } from "react-router-dom";
import router from "./route";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Cookie from "./components/cookies";
import { HelmetProvider } from "react-helmet-async";
// import { HelmetProvider } from "react-helmet-async";
function App() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const backTotop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
      <Tooltip id="backToTop" place="left" effect="solid">Back To Top</Tooltip>
      {isVisible && (
        <button onClick={backTotop} className="back-to-top" data-tooltip-id="backToTop">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v7a2 2 0 0 0 2 2h4l.15 -.005a2 2 0 0 0 1.85 -1.995l-.001 -7h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"></path>
          </svg>
        </button>
      )}
      <Tooltip id="contactNow" place="left" effect="solid">Contact Now</Tooltip>
      <a href={"/contact"}>
        <button className="contactBtn" data-tooltip-id="contactNow">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeWidth="2"
              d="M1,2 L22,2 L22,18 L14,18 L6,22 L6,18 L1,18 L1,2 Z M6,10 L7,10 L7,11 L6,11 L6,10 Z M11,10 L12,10 L12,11 L11,11 L11,10 Z M16,10 L17,10 L17,11 L16,11 L16,10 Z"
            ></path>
          </svg>
        </button>
      </a>
      <>
        <Cookie />
      </>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            border: "2px solid #583fbb",
            padding: "5px",
            fontStyle: "normal",
            backgroundColor:
              "linear-gradient(180deg, #FBF9FF 0%, rgba(235, 232, 255, 0.7) 100%)",
            letterSpacing: "0.5px",
            color: "#6C6C6C",
            fontWeight: 600,
            maxWidth: 450,
            fontSize: "12px",
            top: "84px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
            marginBottom: "10px",
          },
        }}
        position="top-center"
        reverseOrder={true}
      />
    </>
  );
}
export default App;
