import React from "react";
import "./blogherobanner.scss";
import RectangleImage from "../../../assets/images/Rectangle 10.svg";
import CardImage1 from "../../../assets/images/Rectangle 513.svg";
import CardImage2 from "../../../assets/images/Rectangle 514.svg";
import CardImage3 from "../../../assets/images/Rectangle 515.svg";
import CardImage4 from "../../../assets/images/Rectangle 516.svg";
import { NavLink } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import CommonHead from "../../../common/CommonHead";
export default function BlogHero() {
  const Blog_card = [
    {
      image: CardImage1,
      desc: "A strategic approach to UI/UX design involves planning and aligning design decisions with broader business goals. It goes beyond creating visually appealing interfaces and focuses on achieving specific outcomes, such as increased user engagement, conversion rates, and customer satisfaction.",
      read_more: "Read More",
    },
    {
      image: CardImage2,
      desc: " In the ever-evolving landscape of technology, the integration of Artificial Intelligence (AI) and Machine Learning (ML) into everyday applications has ushered in a new era of unprecedented convenience and innovation.",
      read_more: "Read More",
    },
    {
      image: CardImage3,
      desc: "online container booking is a powerful tool for streamlining international logistics, but success hinges on careful planning and informed decision-making.",
      read_more: "Read More",
    },
    {
      image: CardImage4,
      desc: "Are you a small business owner looking to boost your online presence? Look no further! Our dedicated website maintenance service in USA.",
      read_more: "Read More",
    },
  ];
  return (
    <>
      <CommonHead Heading="Insights & Innovations: Our Digital Blog" Sub="Blog" />
      <section className="blog_herobanner">
        <div className="container">
          <div className="main-grid-image">
            <div className="image-lg">
              <Zoom>
                <img src={RectangleImage} title="Ui/Ux" alt="heroImage" />
              </Zoom>
              <Fade bottom duration={600} delay={400}>
                <h2 title="Strategic UI/UX: Designing Impactful Experiences: Part-1">
                  Strategic UI/UX: Designing Impactful Experiences: Part-1
                </h2>
              </Fade>
              <Fade bottom duration={700} delay={500}>
                <p
                  title="Discover the art of Strategic UI/UX design for impactful digital
                experiences. Uncover key principles, tools, and techniques to
                craft user-centric interfaces that drive engagement and
                satisfaction."
                >
                  Discover the art of Strategic UI/UX design for impactful digital
                  experiences. Uncover key principles, tools, and techniques to
                  craft user-centric interfaces that drive engagement and
                  satisfaction.
                </p>
              </Fade>
              <Fade bottom duration={800} delay={600}>
                <NavLink to="/blogdetails">
                  <button title="Learn More">Learn More</button>
                </NavLink>
              </Fade>
            </div>
            <div className="image-md">
              {Blog_card &&
                Blog_card?.map((el, i) => {
                  return (
                    <div className="text-flex" key={i}>
                      <div className="image-card">
                        <Zoom>
                          <img src={el?.image} title="card" alt="card" />
                        </Zoom>
                      </div>
                      <div className="text-card">
                        <Fade bottom duration={600} delay={400}>
                          <p title={el?.desc}>{el?.desc}</p>
                        </Fade>
                        <NavLink to="/blogdetails">
                          <Fade bottom duration={700} delay={500}>
                            <h3 title={el?.read_more}>{el?.read_more}</h3>
                          </Fade>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
