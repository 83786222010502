import React, { useEffect, useRef, useState } from "react";
import "./BDHerobanner.scss";
import BlogDetailsImage from "../../../assets/images/Rectangle blog-details-image.svg";
// import BlogDetailsBook from "../../../assets/images/Blog-details-book.svg";
import Laptop from "../../../assets/images/Rectangle Blog-Details.svg";
import { Fade, Zoom } from "react-reveal";
import { Link, NavLink } from "react-router-dom";
import ShareModal from "../../../common/ShareModal";
import Blog from "../../../common/LatestBlog";
export default function BDHerobanner() {
  const [isOpen, setIsOpen] = useState(true);
  const toggleContents = () => {
    setIsOpen(!isOpen);
  };
  const FAQs = [
    {
      Question: "1. What is a UX UI role?",
      Answer:
        "The UX designer role is to make a product or service usable, enjoyable, and accessible. While many companies design user experiences, the term is most often associated with digital design for websites and apps.",
    },
    {
      Question: "2. Is UI UX a coding job?",
      Answer: 'No, you do"t need to know how to code to be a UX/ UI designer.',
    },
    {
      Question: "3. What is UI UX Web Designer?",
      Answer:
        "User experience (UX) design is the process of building products that are useful, easy, and enjoyable for people to use.",
    },
    {
      Question: "4. Which language is used in UI/UX?",
      Answer:
        "Along with CSS and JavaScript, HTML is one of the three fundamental building blocks of the web—and, as a UX designer.",
    },
  ];
  const [dropdown, setDropdown] = useState(false);
  const answerRef = useRef([]);

  const handleOnclick = (index) => {
    setDropdown(dropdown === index ? false : index);
  };

  useEffect(() => {
    if (dropdown !== false) {
      answerRef.current[
        dropdown
      ].style.height = `${answerRef.current[dropdown].scrollHeight}px`;
    }
  }, [dropdown]);
  return (
    <section className="blog_details_herobanner">
      <div className="container">
        <div className="blog_detail_heading">
          <div className="common_Tab">
            <a href="/blog" title="All Blogs">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                className="icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z"></path>
              </svg>
              All Blogs
            </a>
            <ShareModal />
          </div>
          <Fade bottom duration={600} delay={400}>
            <p title="27 March 2024">27 March 2024</p>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <h1 title="Strategic­ UI/UX­ Designing Impactful Experiences Part-1">
              Strategic­ UI/UX­ Designing Impactful Experiences Part-1
            </h1>
          </Fade>
          <Zoom delay={600}>
            <img src={BlogDetailsImage} title="Strategic­ UI/UX­ Designing Impactful Experiences Part-1" alt="Strategic­ UI/UX­ Designing Impactful Experiences Part-1" />
          </Zoom>
        </div>
        <div className="Blog_Layout_Row">
          <div className="Blog_Left_Col">
            <div className="Table">
              <p
                onClick={toggleContents}
                title="TABLE OF CONTENTS"
                className="Title"
              >
                TABLE OF CONTENTS
                <svg
                  className="Icon"
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isOpen ? (
                    <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path>
                  ) : (
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                  )}
                </svg>
              </p>
              {isOpen && (
                <ol>
                  <a href="#user">
                    <li title="User Interface (UI) Design">
                      User Interface (UI) Design
                    </li>
                  </a>
                  <a href="#experience">
                    <li title="User Experience (UX) Design">
                      User Experience (UX) Design
                    </li>
                  </a>
                  <a href="#ui/ux">
                    <li title="UI/UX Design">UI/UX Design</li>
                  </a>
                  <a href="#characteristics">
                    <li title="Characteristics of impactful experiences include">
                      Characteristics of impactful experiences include
                    </li>
                  </a>
                  <a href="#conclusion">
                    <li title="Conclusion">Conclusion</li>
                  </a>
                  <a href="#FAQs">
                    <li title="FAQs">FAQs</li>
                  </a>
                </ol>
              )}
            </div>
          </div>
          <div className="Blog_Center_Col">
            <div className="text_paragraph">
              <Fade bottom duration={800} delay={400}>
                <div id="user">
                  <h2 title="User Interface (UI) Design">
                    User Interface (UI) Design
                  </h2>
                  <p>
                    User Interface (UI) Design focuses on the visual aspects of
                    an application or website. It involves the arrangement of
                    buttons, icons, images, and other elements to create an
                    aesthetically pleasing and user-friendly interface. UI
                    design aims to enhance the overall look and feel of a
                    digital product, making it visually appealing and easy to
                    navigate.
                  </p>
                </div>
              </Fade>
              <Fade bottom duration={900} delay={500}>
                <div id="experience">
                  <h2 title="User Experience (UX) Design">
                    User Experience (UX) Design
                  </h2>
                  <p>
                    User Experience (UX) Design,on the other hand, is a broader
                    concept. It encompasses all aspects of the end user's
                    interaction with a company, its services, and its products.
                    UX design involves understanding user behavior, conducting
                    research, and creating seamless, enjoyable experiences. It
                    goes beyond the visual interface to consider the overall
                    journey a user takes from the first interaction to task
                    completion.
                  </p>
                </div>
              </Fade>
              <Fade bottom duration={900} delay={600}>
                <div id="ui/ux">
                  <h2 title="UI/UX Design">UI/UX Design</h2>
                  <p>
                    UI/UX Design is an interdisciplinary field that combines
                    elements of graphic design, psychology, and human-computer
                    interaction. It plays a crucial role in shaping how users
                    perceive and interact with digital products, ultimately
                    influencing their satisfaction and loyalty.
                  </p>
                </div>
              </Fade>
              <Fade bottom duration={900} delay={700}>
                <blockquote>
                  Also read this article:{" "}
                  <a
                    title="Strategic­ UI/UX­ Designing Impactful"
                    href="/blogdetails"
                  >
                    Strategic­ UI/UX­ Designing Impactful
                  </a>
                </blockquote>
              </Fade>
            </div>
            <div className="main-grid-blog-details">
              <div className="bd-text">
                <Fade bottom duration={600} delay={400}>
                  <h2 title="Importance of a Strategic Approach">
                    Importance of a Strategic Approach
                  </h2>
                </Fade>
                <Fade bottom duration={700} delay={500}>
                  <p>
                    A{" "}
                    <b>
                      strategic approach to{" "}
                      <a title="UI/UX design" href="/blogdetails">
                        UI/UX design
                      </a>
                    </b>{" "}
                    involves planning and aligning design decisions with broader
                    business goals. It goes beyond creating visually appealing
                    interfaces and focuses on achieving specific outcomes, such
                    as increased user engagement, conversion rates, and customer
                    satisfaction.
                  </p>
                </Fade>
                <NavLink>
                  <Fade bottom duration={800} delay={600}>
                    <button title="Learn More">Learn More</button>
                  </Fade>
                </NavLink>
              </div>
              {/* <div className="bd-image">
                <Zoom delay={700}>
                  <img src={BlogDetailsBook} alt="book" />
                </Zoom>
              </div> */}
            </div>
            <div id="characteristics" className="blog-details_text">
              <Fade bottom duration={600} delay={400}>
                <h3 title="Characteristics of impactful experiences include:">
                  Characteristics of impactful experiences include:
                </h3>
              </Fade>
              <Fade bottom duration={700} delay={500}>
                <ul>
                  <li>
                    Seamless Interaction: Users can navigate effortlessly
                    through the interface, completing tasks without unnecessary
                    complications.
                  </li>
                  <li>
                    Delightful Moments: Incorporating elements that surprise and
                    delight users, creating memorable and positive associations.
                  </li>
                  <li>
                    Personalization: Tailoring the experience to individual user
                    preferences, making interactions more relevant and
                    meaningful.
                  </li>
                  <li>
                    Consistency: Providing a consistent experience across
                    different devices and touchpoints, reinforcing brand
                    identity and usability.
                  </li>
                  <li>
                    Feedback and Responsiveness: Offering clear feedback for
                    user actions and ensuring the system responds promptly,
                    enhancing the feeling of control and engagement.
                  </li>
                </ul>
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <img src={Laptop} title="laptop" alt="laptop" />
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <h3 title="Principles of Good Design">
                  1. Principles of Good Design
                </h3>
              </Fade>
              <Fade bottom duration={700} delay={500}>
                <h4>
                  Good design is at the heart of effective UI/UX. Understanding
                  and implementing key design principles is crucial for creating
                  interfaces that are not only aesthetically pleasing but also
                  functional and user-friendly. Some fundamental principles
                  include:
                </h4>
              </Fade>
              <Fade bottom duration={800} delay={600}>
                <ul>
                  <li>
                    Clarity: Ensuring that the design communicates its purpose
                    clearly to users.
                  </li>
                  <li>
                    Consistency: Maintaining a uniform design language
                    throughout the interface.
                  </li>
                  <li>
                    Hierarchy: Organizing elements to guide users' attention and
                    prioritize information.
                  </li>
                  <li>
                    Simplicity: Striving for simplicity without sacrificing
                    functionality.
                  </li>
                  <li>
                    Feedback: Providing users with clear feedback on their
                    interactions.
                  </li>
                </ul>
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <div className="inner_Image">
                  <img
                    src="https://cdna.artstation.com/p/assets/images/images/059/110/182/large/yudiz-solutions-5809368.jpg?1675679624"
                    title="Ui-Ux"
                    alt="Ui-Ux"
                  />
                </div>
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <h3 title="Historical Evolution of UI/UX">
                  2. Historical Evolution of UI/UX
                </h3>
              </Fade>
              <Fade bottom duration={700} delay={500}>
                <h4>
                  The field of{" "}
                  <a title="UI/UX design" href="/blogdetails">
                    UI/UX design
                  </a>{" "}
                  has evolved significantly over time. Initially, the focus was
                  primarily on functionality, with aesthetics taking a backseat.
                  As technology progressed, the importance of user experience
                  gained prominence. Key milestones include:
                </h4>
              </Fade>
              <Fade bottom duration={800} delay={600}>
                <ul>
                  <li>
                    Pre-Computer Era: Understanding how physical interfaces
                    influenced early user interactions.
                  </li>
                  <li>
                    Graphical User Interfaces (GUIs): The shift from
                    command-line interfaces to graphical interfaces.
                  </li>
                  <li>
                    Web Revolution: The emergence of the internet led to a new
                    set of design challenges and opportunities.
                  </li>
                  <li>
                    Mobile Era: The advent of smartphones brought about a mobile
                    ui design approach.
                  </li>
                </ul>
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <div className="inner_Image">
                  <img
                    src="https://i0.wp.com/upswing-tech.com/wp-content/uploads/2020/12/technology_resized.jpg?w=1200&ssl=1"
                    title="Key Components and Elements"
                    alt="Key Components and Elements"
                  />
                </div>
              </Fade>
              <Fade bottom duration={600} delay={400}>
                <h3 title="Key Components and Elements">
                  3. Key Components and Elements
                </h3>
              </Fade>
              <Fade bottom duration={700} delay={500}>
                <h4>
                  <a title="UI/UX design" href="/blogdetails">
                    UI/UX design
                  </a>{" "}
                  involves a set of foundational components and elements that
                  collectively contribute to a cohesive user experience: Layout:
                  Arrangement of visual elements on a page to create a logical
                  flow.
                </h4>
              </Fade>
              <Fade bottom duration={800} delay={600}>
                <ul>
                  <li>
                    Color Scheme: Strategic use of colors to convey information
                    and evoke emotions.
                  </li>
                  <li>
                    Typography: Selecting fonts and text styles to enhance
                    readability and convey the brand personality.
                  </li>
                  <li>
                    Images and Icons: Visual elements that aid in communication
                    and navigation.
                  </li>
                  <li>
                    Navigation: The structure and organization of the interface
                    to facilitate user movement.
                  </li>
                </ul>
              </Fade>
              <Fade bottom duration={900} delay={700}>
                <p>
                  Understanding the historical context and mastering these
                  foundational elements is essential for designers to build upon
                  and create meaningful, user-centric experiences. As technology
                  continues to advance, these principles provide a solid
                  framework for adapting to new challenges and opportunities in
                  the dynamic field of UI/UX design.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={600} delay={400}>
              <div id="conclusion" className="conclusion">
                <Fade bottom duration={900} delay={700}>
                  <h3 title="Conclusion">Conclusion</h3>
                </Fade>
                <Fade bottom duration={900} delay={700}>
                  <h4>
                    In summary, strategic UI/UX design is about more than just
                    aesthetics; it's about crafting seamless and meaningful
                    experiences that align with user expectations and business
                    objectives. By focusing on understanding the user's journey,
                    leveraging research-driven insights, and applying design
                    principles that enhance functionality and engagement,
                    designers can create products that are not only visually
                    appealing but also highly effective. A thoughtful balance of
                    usability, accessibility, and innovation leads to impactful
                    user experiences that drive long-term success.
                  </h4>
                </Fade>
              </div>
            </Fade>
            <Fade bottom duration={600} delay={400}>
              <div id="FAQs" className="FAQ">
                <div className="FAQs_Title">
                  <h2 title="FAQs">FAQs</h2>
                </div>
                {FAQs?.map((el, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleOnclick(index)}
                      title={el?.Question}
                      className={`FAQs_Main ${dropdown === index ? "open" : ""
                        }`}
                    >
                      <div className="FAQ_Question">
                        <div className="FAQ_ALigment">
                          <p>{el?.Question}</p>
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            height="20px"
                            width="20px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {dropdown === index ? (
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            ) : (
                              <>
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </>
                            )}
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`FAQ_Answer ${dropdown === index ? "open" : ""
                          }`}
                        ref={(el) => (answerRef.current[index] = el)}
                        style={{
                          height:
                            dropdown === index
                              ? `${answerRef.current[index]?.scrollHeight}px`
                              : "0",
                        }}
                      >
                        {dropdown === index && <span>{el.Answer}</span>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fade>
            <Fade bottom duration={600} delay={400}>
              <div className="cardNewsLetter">
                <h3 title="Vedhas Ai Technology">Vedhas Ai Technology</h3>
                <p title="Get exclusive access to insider AI stories, tips and tricks. Sign up to the newsletter and be in the know!">
                  Get exclusive access to insider AI stories, tips and tricks.
                  Sign up to the newsletter and be in the know!
                </p>
                <form className="form">
                  <div className="Input_Cover">
                    <input
                      required
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      id=""
                    />
                  </div>
                  <div className="Input_Cover">
                    <input
                      required
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      id=""
                    />
                  </div>
                  <div className="button">
                    <button title="Subscribe ➤" type="submit">
                      Subscribe ➤
                    </button>
                  </div>
                </form>
              </div>
            </Fade>
          </div>
        </div>
        <div className="latestBlog">
          <Fade bottom duration={600} delay={400}>
            <h1 className="blog_post_heading" title="Latest Blog Post">
              Latest Blog Post
            </h1>
          </Fade>
          <Blog />
          <div className="button">
            <Link to={`/blog`}>
              <button title=" Read All Blogs ➤">Read All Blogs ➤</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
