import React from "react";
import "./testimonials.scss";
import Slider from "react-slick";

export default function Testimonials({ rating = 5 }) {

    const review = [
        {
            title: "⭐ AssignmentGPT – Simplifying Academic Success",
            authorName: " Sarah L., University Student",
            content:
                "AssignmentGPT has been a game-changer for my studies. It helps generate detailed and accurate content with just a few inputs. I’ve never experienced a tool that is so intuitive and easy to use.",
        },
        {
            title: "⭐ ByPass AI – Unlocking Access with Ease",
            authorName: "David M., IT Manager at TechPro Solutions",
            content:
                "ByPass AI has allowed us to automate security procedures without compromising performance. It’s reliable and integrates seamlessly with our systems.",
        },
        {
            title: "⭐ AI Checker – Ensuring Quality and Originality",
            authorName: "Emma T., Content Manager",
            content:
                "The AI Checker is essential for all our academic and business documents. It quickly detects AI-generated content, ensuring originality and compliance.",
        },
        {
            title: "⭐ AssignmentGPT – Simplifying Academic Success",
            authorName: "John D., Lecturer",
            content:
                "We integrated Vedhas AI into our marketing strategy, and the results have been phenomenal. The analytics and content suggestions have significantly boosted our online presence and engagement.",
        },
        {
            title: "⭐ ByPass AI – Unlocking Access with Ease",
            authorName: "David M., IT Manager at TechPro Solutions",
            content:
                "This tool has simplified data management for my team. With ByPass AI, tedious verification processes are automated, letting us focus on more strategic work.",
        },
        {
            title: "⭐ AI Checker – Ensuring Quality and Originality",
            authorName: "Priya R., Operations Head at NextGen Software",
            content:
                "AI Checker has been a reliable companion for our editorial team. It has helped us filter and refine AI-generated outputs efficiently.",
        },
    ];
    const Star = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M18.0002 0H9.00537V18.0001H18.0002V0Z"
                fill="#00B67B"
            ></path>
            <path
                d="M9.00524 0H0V18.0001H9.00524V0Z"
                fill="#00B67B"
            ></path>
            <path
                d="M9.00575 12.1358L11.7397 11.4366L12.8771 14.9636L9.00575 12.1358ZM15.2666 7.57576H10.4771L9.00575 3.03662L7.56575 7.56533H2.70312L6.60575 10.3723L5.12401 14.8697L9.01619 12.0627L11.4162 10.3306L15.298 7.56533L15.2666 7.57576Z"
                fill="#fff"
            ></path>
        </svg>
    );
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2, 
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    };


    return (
        <div className="Testimonials_Sec">
            <div className="container">
                <div className="Testimonials_Heading">
                    <h2 title="Testimonials">Testimonials</h2>
                </div>
                <div className="slider-container">
                    <Slider {...settings}>
                        {review?.map((el, index) => {
                            return (
                                <div key={index} className="Testimonial">
                                    <div title={el?.title} className="Testimonial_main">
                                        <div className="Testimonial_Title">
                                            <p title={el?.title}>{el?.title}</p>
                                        </div>
                                        <div className="Testimonial_review">
                                            <p title={el?.content}>{el?.content}</p>
                                        </div>
                                        <div className="Testimonial_Star">
                                            {[...Array(5)].map((_, index) => (
                                                <div className="star">
                                                    <Star key={index} filled={index < rating} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="Testimonial_Author">
                                            <span title={el?.authorName}>{el?.authorName}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
