import React, { useState } from 'react'
import './ourTechnology.scss';
import apple from '../../../assets/VEDHAS AI/apps.svg';
import android from '../../../assets/VEDHAS AI/andirod.svg';
import flutter from '../../../assets/VEDHAS AI/flutter.svg';
import kotlin from '../../../assets/VEDHAS AI/kotlin.svg';
import react from '../../../assets/VEDHAS AI/recet.svg';
import next from '../../../assets/VEDHAS AI/next js.svg';
import boostrap from '../../../assets/VEDHAS AI/boostrap.svg';
import firebase from '../../../assets/VEDHAS AI/firebase.svg';
import mongoDb from '../../../assets/VEDHAS AI/mongodb 2.svg';
import starpi from '../../../assets/VEDHAS AI/stafi.svg';
import nodeJs from '../../../assets/VEDHAS AI/nodejs.svg';
import python from '../../../assets/VEDHAS AI/python.svg';
import figma from '../../../assets/VEDHAS AI/figma.svg';
import xd from '../../../assets/VEDHAS AI/xd.svg';

export default function OurTechnology() {
    const [activeTab, setActiveTab] = useState(0);
    const Technology = [
        {
            name: 'Mobile',
            Icon: [
                {
                    name: 'iOS',
                    icon: apple
                },
                {
                    name: 'Android',
                    icon: android
                },
                {
                    name: 'Flutter',
                    icon: flutter
                },
                {
                    name: 'Kotlin',
                    icon: kotlin
                }
            ]
        },
        {
            name: 'Frontend',
            Icon: [
                {
                    name: 'ReactJS',
                    icon: react
                },
                {
                    name: 'NextJS',
                    icon: next
                },
                {
                    name: 'Boostrap',
                    icon: boostrap
                }
            ]
        },
        {
            name: 'Database',
            Icon: [
                {
                    name: 'Firebase',
                    icon: firebase
                },
                {
                    name: 'Mongo db',
                    icon: mongoDb
                },
                {
                    name: 'Starpi',
                    icon: starpi
                }
            ]
        },
        {
            name: 'Backend',
            Icon: [
                {
                    name: 'Node Js',
                    icon: nodeJs
                },
                {
                    name: 'Python',
                    icon: python
                }
            ]
        },
        {
            name: 'UI/UX',
            Icon: [
                {
                    name: 'Figma',
                    icon: figma
                },
                {
                    name: 'Xd',
                    icon: xd
                }
            ]
        }
    ]
    return (
        <div className='ourTechnology'>
            <div className="container">
                <div className="ourTechnologyHeading">
                    <div className="Title">
                        <h2>Our Technology Framework</h2>
                    </div>
                    <div className="detail">
                        <p>“Every successful software application is powered by a thoughtfully crafted tech stack, guaranteeing top performance, scalability, and a flawless user experience.”</p>
                    </div>
                </div>
                <ul className='tabs-list'>
                    {
                        Technology?.map((el, index) => {
                            return (
                                <li key={index}
                                    className={activeTab === index ? 'active' : ''}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {el?.name}
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="Technology_Icons">
                    {Technology[activeTab].Icon.map((icon, idx) => (
                        <div key={idx} className="icon-item">
                            <div className="icon">
                                <img src={icon?.icon} title={icon?.name} alt={icon?.name} />
                            </div>
                            <p title={icon?.name}>{icon?.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
