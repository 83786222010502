import React from 'react'
import Form from '../components/ContactComponent/ContactForm'
import { Helmet } from 'react-helmet-async'

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Get in Touch: Reach Out to Vedhas AI</title>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="Get in touch with Vedhas AI to explore innovative solutions and collaboration opportunities. We're here to answer your questions and help you navigate the world of AI." />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="768" />
        <meta property="og:title" content="Get in Touch: Reach Out to Vedhas AI" />
        <meta property="og:description" content="Get in touch with Vedhas AI to explore innovative solutions and collaboration opportunities. We're here to answer your questions and help you navigate the world of AI." />
        <meta property="og:site_name" content="vedhasaitech.com/contact" />
        <meta property="og:url" content="https://vedhasaitech.com/contact" />
        <meta property="og:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta property="og:image:alt" content="Ai Technology" />
        <meta property="og:image:secure_url"
          content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:url" href="https://vedhasaitech.com/contact" />
        <meta name="twitter:title" content="Get in Touch: Reach Out to Vedhas AI" />
        <meta name="twitter:description" content="Get in touch with Vedhas AI to explore innovative solutions and collaboration opportunities. We're here to answer your questions and help you navigate the world of AI." />
        <meta name="twitter:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:image:alt" content="Ai Technology" />
        <link rel="canonical" href="https://vedhasaitech.com/contact" />
      </Helmet>
      <Form />
    </>
  )
}
