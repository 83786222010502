import React from "react";
import "./blogpost.scss";
// import CardImage1 from "../../../assets/images/blog-post1.svg";
// import CardImage2 from "../../../assets/images/blog-post2.svg";
// import CardImage3 from "../../../assets/images/blog-post3.svg";
// import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
import Blog from "../../../common/LatestBlog";
export default function BlogPost() {
  // const Blog_Card = [
  //   {
  //     image: CardImage1,
  //     author_name: "Drashti Ramani",
  //     publish_date: "29 March 2024",
  //     desc: "Smart Solutions: AI/ML App Services Revolutionizing Everyday Experiences.",
  //     read_more: "Read More",
  //   },
  //   {
  //     image: CardImage2,
  //     author_name: "Harshil Thummar",
  //     publish_date: "11 March 2024",
  //     desc: "12 Benefits of Laravel for Enterprise Development.",
  //     read_more: "Read More",
  //   },
  //   {
  //     image: CardImage3,
  //     author_name: "suhashi pandav",
  //     publish_date: "20 February 2024",
  //     desc: "Optimize Workflow: Unleashing DevOps as a Service for Peak Productivity.",
  //     read_more: "Read More",
  //   },
  // ];
  return (
    <section className="new_blog_post">
      <div className="container">
        <Fade bottom duration={600} delay={400}>
          <h1 className="blog_post_heading" title="New Blog Post">
            New Blog Post
          </h1>
        </Fade>
        {/* <div className="image-grid-card">
          {Blog_Card &&
            Blog_Card?.map((el, i) => {
              return (
                <div className="sub-card-image" key={i}>
                  <div className="blog_card_desc" title={el?.desc}>
                    <div className="desc">
                      <Zoom>
                        <img src={el?.image} alt="cardimage" />
                      </Zoom>
                      <Fade bottom duration={600} delay={400}>
                        <p>
                          {el?.author_name}
                          <span>{el?.publish_date}</span>
                        </p>
                      </Fade>
                      <Fade bottom duration={700} delay={500}>
                        <h4>{el?.desc}</h4>
                      </Fade>
                    </div>
                    <NavLink to="/blogdetails">
                      <Fade bottom duration={800} delay={600}>
                        <h3 title={el?.read_more}>{el?.read_more}</h3>
                      </Fade>
                    </NavLink>
                  </div>
                </div>
              );
            })}
        </div> */}
        <Blog />
      </div>
    </section>
  );
}
