import React from "react";
import ProductMain from "../components/ProductComponent/ProductMainPart";
import { Helmet } from "react-helmet-async";

export const Product = () => {
  return (
    <>
      <Helmet>
        <title>Explore Our Product Line: Advanced AI Technologies</title>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="Explore our product line featuring advanced AI technologies designed to enhance efficiency and drive innovation. Discover cutting-edge solutions tailored to meet the evolving needs of various industries." />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="768" />
        <meta property="og:title" content="Explore Our Product Line: Advanced AI Technologies" />
        <meta property="og:description" content="Explore our product line featuring advanced AI technologies designed to enhance efficiency and drive innovation. Discover cutting-edge solutions tailored to meet the evolving needs of various industries." />
        <meta property="og:site_name" content="vedhasaitech.com/product" />
        <meta property="og:url" content="https://vedhasaitech.com/product" />
        <meta property="og:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta property="og:image:alt" content="Ai Technology" />
        <meta property="og:image:secure_url"
          content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:url" href="https://vedhasaitech.com/product" />
        <meta name="twitter:title" content="AI Insights: Your Source for AI Knowledge and Trends" />
        <meta name="twitter:description" content="AI Insights is your go-to source for the latest knowledge and trends in artificial intelligence. We provide expert analysis, industry updates, and practical insights to help you stay informed and make informed decisions in the rapidly evolving AI landscape." />
        <meta name="twitter:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:image:alt" content="Ai Technology" />
        <link rel="canonical" href="https://vedhasaitech.com/product" />
      </Helmet>
      <ProductMain />
    </>
  );
};
