import React from 'react'
import DisClaimer from '../components/Disclaimer'
import { Helmet } from 'react-helmet-async'

export default function Disclaimer() {
    return (
        <>
            <Helmet>
                <title>Important Disclaimer: Please Read Before Engaging with Our Services</title>
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Our platform provides content for informational purposes only and does not constitute professional advice. Users are responsible for any actions taken based on the information provided. We are not liable for any loss or damages arising from the use of our services." />
                <meta property="og:image:width" content="1024" />
                <meta property="og:image:height" content="768" />
                <meta property="og:title" content="Important Disclaimer: Please Read Before Engaging with Our Services" />
                <meta property="og:description" content="Privacy Assurance explains how Vedhas AI safeguards your personal information. We prioritize data security and ensure your privacy through strict protection measures and transparent practices." />
                <meta property="og:site_name" content="vedhasaitech.com/disclaimer" />
                <meta property="og:url" content="https://vedhasaitech.com/disclaimer" />
                <meta property="og:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
                <meta property="og:image:alt" content="Ai Technology" />
                <meta property="og:image:secure_url"
                    content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
                <meta name="twitter:url" href="https://vedhasaitech.com/disclaimer" />
                <meta name="twitter:title" content="Important Disclaimer: Please Read Before Engaging with Our Services" />
                <meta name="twitter:description" content="Our platform provides content for informational purposes only and does not constitute professional advice. Users are responsible for any actions taken based on the information provided. We are not liable for any loss or damages arising from the use of our services." />
                <meta name="twitter:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
                <meta name="twitter:image:alt" content="Ai Technology" />
                <link rel="canonical" href="https://vedhasaitech.com/disclaimer" />
            </Helmet>
            <DisClaimer />
        </>
    )
}
