import React from "react";
import "../services/services.scss";
import Rectangle from "../../../assets/images/icons/Rectangle 529.jpg";
import Group from "../../../assets/images/icons/Group 8870.svg";
import Group1 from "../../../assets/images/icons/Group.svg";
import Group2 from "../../../assets/images/icons/Group (1).svg";
import Group3 from "../../../assets/images/icons/Group (2).svg";
import Group4 from "../../../assets/images/icons/Group (3).svg";
import { Fade } from "react-reveal";
export default function Service() {
  const Service_card = [
    {
      image: Rectangle,
      icon: Group,
      heading: "Software & IT Outsourcing",
      description:
        "Our team of experienced developers and IT professionals are here to support your business needs. From custom software solutions to IT infrastructure management, we ensure your operations run smoothly and efficiently.",
    },
    {
      image: Rectangle,
      icon: Group1,
      heading: "Website & App Development",
      description:
        "We craft responsive, user-friendly websites and mobile apps that engage your audience and drive growth. Whether you need a new website, an app for your business, or an update to your existing digital presence, we've got you covered.",
    },
    {
      image: Rectangle,
      icon: Group2,
      heading: "Digital Marketing & SEO",
      description:
        "Our marketing experts will help your business stand out in a crowded marketplace. We offer comprehensive digital marketing services, including SEO, social media marketing, content creation, and more to ensure your brand reaches its full potential online.",
    },
    {
      image: Rectangle,
      icon: Group3,
      heading: "Innovative Digital Products",
      description:
        "At Vedhas AI Technologies, we don't just work for others—we create our own digital products too. Our innovative solutions are designed to meet the demands of the modern digital landscape, providing value and enhancing productivity for businesses around the globe.",
    },
    {
      image: Rectangle,
      icon: Group4,
      heading: "UI / UX Design",
      description:
        "Transform user experiences with our UI/UX design services. We craft intuitive interfaces and engaging designs tailored to your audience, ensuring seamless interaction and enhancing user satisfaction. Let us elevate your digital presence with captivating design solutions that leave a lasting impression.",
    },
    {
      image: Rectangle,
      icon: Group1,
      heading: "AI/ML Services",
      description:
        "Unlock the potential of artificial intelligence and machine learning with our advanced AI/ML services. We develop intelligent systems that can analyze data, automate processes, and provide actionable insights to drive your business forward.",
    },
  ];
  return (
    <section className="service_section">
      <div className="container">
        <div className="service-heading">
          <Fade bottom duration={600} delay={400}>
            <h2
              className="heading"
              title="Creating New Digital Horizons with Our Services"
            >
              Creating New Digital Horizons with <span>Our Services</span>
            </h2>
          </Fade>
          <Fade bottom duration={700} delay={500}>
            <p title="At Vedhas AI Technologies, we are dedicated to driving innovation and delivering excellence in the digital world. We specialize in providing top-notch software and IT outsourcing services, helping businesses streamline operations and achieve their goals with cutting-edge technology.">
              At Vedhas AI Technologies, we are dedicated to driving innovation
              and delivering excellence in the digital world. We specialize in
              providing top-notch software and IT outsourcing services, helping
              businesses streamline operations and achieve their goals with
              cutting-edge technology.
            </p>
          </Fade>
        </div>
        <div className="main-grid">
          {Service_card &&
            Service_card?.map((el, i) => {
              return (
                <Fade bottom duration={900} delay={800} key={i}>
                  <div className="sub-grid" title={el?.heading}>
                    <div className="position">
                      <img src={el?.image} alt="Service" />
                      <div className="group-image">
                        <img src={el?.icon} alt={el?.heading} />
                      </div>
                    </div>
                    <div className="card-heading">
                      <h2>{el?.heading}</h2>
                      <p>{el?.description}</p>
                      <a title="Read More" href="/services">
                        Read More
                        <span>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 448 512"
                            height="15"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </Fade>
              );
            })}
        </div>
      </div>
    </section>
  );
}
