import React from 'react'
import './blog.scss';
import CardImage1 from "../../../src/assets/images/blog-post1.svg";
import CardImage2 from "../../../src/assets/images/blog-post2.svg";
import CardImage3 from "../../../src/assets/images/blog-post3.svg";
import { Fade, Zoom } from 'react-reveal';
import { NavLink } from 'react-router-dom';

const Blog_Card = [
    {
        image: CardImage1,
        author_name: "Drashti Ramani",
        publish_date: "29 March 2024",
        desc: "Smart Solutions: AI/ML App Services Revolutionizing Everyday Experiences.",
        read_more: "Read More",
    },
    {
        image: CardImage2,
        author_name: "Harshil Thummar",
        publish_date: "11 March 2024",
        desc: "12 Benefits of Laravel for Enterprise Development.",
        read_more: "Read More",
    },
    {
        image: CardImage3,
        author_name: "suhashi pandav",
        publish_date: "20 February 2024",
        desc: "Optimize Workflow: Unleashing DevOps as a Service for Peak Productivity.",
        read_more: "Read More",
    },
];

export default function Blog() {
    return (
        <div className='container'>
            <div className="image-grid-card">
                {Blog_Card &&
                    Blog_Card?.map((el, i) => {
                        return (
                            <div className="sub-card-image" key={i}>
                                <div className="blog_card_desc">
                                    <div className="desc">
                                        <Zoom>
                                            <img src={el?.image} title={el?.desc} alt="cardimage" />
                                        </Zoom>
                                        <Fade bottom duration={600} delay={400}>
                                            <p title={el?.author_name}>
                                                {el?.author_name}
                                                <span title={el?.publish_date}>{el?.publish_date}</span>
                                            </p>
                                        </Fade>
                                        <Fade bottom duration={700} delay={500}>
                                            <h4 title={el?.desc}>{el?.desc}</h4>
                                        </Fade>
                                    </div>
                                    <NavLink to="/blogdetails">
                                        <Fade bottom duration={800} delay={600}>
                                            <h3 title={el?.read_more}>{el?.read_more}</h3>
                                        </Fade>
                                    </NavLink>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    )
}
