import React from "react";
import AboutMainPart from "../components/AboutComponent/AboutMainPart";
import { Helmet } from "react-helmet-async";
export const About = () => {
  return (
    <>
      <Helmet>
        <title>About Vedhas AI: Revolutionizing Industries with AI</title>
        <meta name="description" content="About Vedhas AI: Revolutionizing Industries with AI" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="768" />
        <meta property="og:title" content="About Vedhas AI: Revolutionizing Industries with AI" />
        <meta property="og:description" content="Vedhas AI is revolutionizing industries through advanced artificial intelligence solutions. We empower businesses to boost efficiency, streamline operations, and unlock growth opportunities, transforming the future of work with innovative technology." />
        <meta property="og:site_name" content="vedhasaitech.com/about" />
        <meta property="og:url" content="https://vedhasaitech.com/about" />
        <meta property="og:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta property="og:image:alt" content="Ai Technology" />
        <meta property="og:image:secure_url"
          content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:url" href="https://vedhasaitech.com/about" />
        <meta name="twitter:title" content="About Vedhas AI: Revolutionizing Industries with AI" />
        <meta name="twitter:description" content="Vedhas AI is revolutionizing industries through advanced artificial intelligence solutions. We empower businesses to boost efficiency, streamline operations, and unlock growth opportunities, transforming the future of work with innovative technology." />
        <meta name="twitter:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:image:alt" content="Ai Technology" />
        <link rel="canonical" href="https://vedhasaitech.com/about" />
      </Helmet>
      <AboutMainPart />
    </>
  );
};
