import React from "react";
import CompanyLogo from "../../assets/VEDHAS AI/02 (2).png";
import { Link } from "react-router-dom";

export default function TearmsCondition() {
    return (
        <div className="TermsAndConditions_PrivacyPolicy_Section">
            <div className="container">
                <h1 title="Terms & Conditions">Terms & Conditions</h1>
                <div className="TermsAndConditions_PrivacyPolicy_info">
                    <p>
                        This Website is established and managed by{" "}
                        <a rel='noopener noreferrer' title="Vedhas Ai" target="_blank" href="https://vedhasaitech.com/">
                            Vedhas Ai
                        </a>
                        , whose registered office is located at Gujrat, India.” This entity is
                        represented by its Directors and is hereinafter referred to as the
                        “Company,” a term which includes its legal heirs, representatives,
                        administrators, permitted successors, and assigns.
                    </p>
                    <p>
                        This legal agreement constitutes an electronic record in accordance
                        with the <b title="Indian Information Technology Act, 2000">Indian Information Technology Act, 2000,</b> and the
                        applicable rules and amended provisions regarding electronic records
                        in various statutes as amended by the Indian Information Technology
                        Act, 2000. It's important to note that this electronic record is
                        generated by a computer system and does not require physical or
                        digital signatures.
                    </p>
                    <p>
                        This legal document is published in compliance with Rule 3 (1) of the
                        Indian Information Technology (Intermediaries guidelines) Rules, 2011,
                        and Rule 4 of the Information Technology (Reasonable security
                        practices and procedures and sensitive personal data or information)
                        Rules, 2011, as amended through the Information Technology Amendment
                        Act, 2008. These rules mandate the publication of the Terms of
                        Services and practices for accessing or using{" "}
                        <a rel='noopener noreferrer' title="www.vedhasaitech.com" target="_blank" href="https://vedhasaitech.com/">
                            www.vedhasaitech.com
                        </a>{" "}
                        (“website/platform”).
                    </p>
                    <p>
                        The creator of these Terms of Service is committed to ensuring your
                        use of the platform. This document contains information about the
                        Website{" "}
                        <a rel='noopener noreferrer' target="_blank" title="www.vedhasaitech.com" href="https://vedhasaitech.com/">
                            “www.vedhasaitech.com”
                        </a>
                        (referred to as the “Platform” hereinafter).
                    </p>
                    <p>
                        For the purpose of these Terms of Use (“Terms”), when the context
                        requires, “We,” “Our,” and “Us” refer to the Platform and/or the
                        Company. “You,” “Your,” “Yourself,” and “User” refer to natural and
                        legal individuals who use this Platform and are competent to enter
                        into binding contracts as per Indian laws. “Third Parties” encompass
                        any Application, Company, or individual apart from the Users and the
                        creator of this platform. “Platform” designates the Website created by
                        the Company, which enables Users to access services from the Company
                        through the Website.
                    </p>
                    <h2 title="General Terms">1.General Terms</h2>
                    <p>
                        The section headings in these Terms or Privacy Policy are for
                        organizational purposes only and do not alter the provisions within
                        these documents. Both Parties agree that these headings have no legal
                        or contractual significance concerning the User's use of the services
                        on the Platform.
                    </p>
                    <p>
                        The use of this Platform by Users is solely governed by these Terms,
                        the Privacy Policy, and other policies that may be uploaded on the
                        platform for effective applicability, along with any modifications or
                        amendments made by the Company at its sole discretion. By continuing
                        to access and use this Platform, you, as a User, agree to comply with
                        and be bound by the following Terms and Conditions, Privacy Policy,
                        and any other applicable policies available on the Platform.
                    </p>
                    <p>
                        The User acknowledges that these Terms and Policy are interrelated and
                        that the expiration or termination of one will lead to the termination
                        of the other.
                    </p>
                    <p>
                        The User explicitly agrees that these Terms and the aforementioned
                        Policies form a legally binding agreement between the User and the
                        Company. The User is subject to the rules, guidelines, policies,
                        terms, and conditions applicable to any service provided by the
                        Platform, which are deemed to be incorporated into these Terms and
                        shall be treated as part of the same. No signature or express act is
                        required to make these Terms and the Policy binding on the User; the
                        User's act of visiting any part of the Platform constitutes their full
                        and final acceptance of these Terms and the aforementioned Policy.
                    </p>
                    <p>
                        The Company reserves the exclusive right to amend or modify these
                        Terms without prior permission or intimation to the User, and such
                        amendments or modifications shall take immediate effect. Failure to
                        adhere to the changes requires the User to cease using the Services.
                        Continuous use of the Services implies acceptance of the changed
                        terms.
                    </p>
                    <h2 title="Platform Overview">2.Platform Overview</h2>
                    <p>
                        The Platform, created and operated by the Company, serves as a managed
                        marketplace connecting businesses and professionals for various
                        business services, including content writing, graphic designing, web
                        development, and other such services needed by businesses or
                        individuals. The Platform facilitates online professional services as
                        an intermediary between end-clients (beneficiary receiving services)
                        and freelancers (service providers). The Platform offers two primary
                        models: users can opt for a one-off service or choose a monthly
                        subscription that allows multiple services within the monthly charge.
                        Each service and subscription will have a clearly communicated price
                        to the end-user upon request.
                    </p>
                    <h2 title="Registration">3.Registration</h2>
                    <p>
                        Registration is mandatory on the platform for users to avail
                        themselves of the platform's services through service providers.
                        Information provided during registration will be stored in our
                        database. <b title="Users can sign up using their Google Credentials">Users can sign up using their Google Credentials</b> or
                        create an account using their <b title="email address">email address.</b> The platform will
                        collect preferred names, phone numbers, and company details (optional)
                        to enhance the user experience and generate invoices accurately. The
                        platform's main data collection includes email addresses, phone
                        numbers, and company details to build user profiles in our database.
                    </p>
                    <h2 title="Eligibility">4.Eligibility</h2>
                    <p>
                        Users represent and warrant that they are competent and eligible to
                        enter <b title="legally binding agreements">legally binding agreements</b>, are at{" "}
                        <b title="least 18 years old">least 18 years old</b>,and have the authority to bind themselves to
                        these Terms in accordance with the law. Users further agree to comply
                        with this Agreement and all applicable local, state, national, and
                        international laws, rules, and regulations. Users may not use the
                        Platform if they are not competent to contract or are disqualified by
                        any other applicable law, rule, or regulation currently in force.
                    </p>
                    <h2 title="Content">5.Content</h2>
                    <p>
                        All content on the Platform, including text, graphics, User
                        interfaces, photographs, trademarks, logos, brand names, descriptions,
                        sounds, music, and artwork (collectively, ‘Content’), is generated or
                        provided based on information from third parties. The Platform does
                        not control or guarantee the quality, accuracy, integrity, or
                        genuineness of such content or other information provided on the
                        Platform.
                    </p>
                    <p>
                        All Content displayed on the Platform is subject to copyright and may
                        not be reused by any party (or a third party) without the prior{" "}
                        <b title="written consent of the Company and the copyright owner">written consent of the Company and the copyright owner.</b>
                    </p>
                    <p>
                        Users are solely responsible for the integrity, authenticity, quality,
                        and genuineness of the content they provide on the Platform. While
                        feedback and comments by Users can be made via the Platform, the
                        Platform bears no liability for any feedback or comments made by Users
                        or made in respect of any content on the Platform. The Platform
                        reserves the right to suspend the account of any User for an
                        indefinite period at its discretion or to terminate the account of any
                        User who is found to have created, shared, or submitted any Content
                        that is untrue, inaccurate, misleading, offensive, or vulgar. The User
                        shall be solely responsible for any financial or legal losses incurred
                        through the creation, sharing, or submission of such Content.
                    </p>
                    <p>
                        Users have a personal, non-exclusive, non-transferable, revocable,
                        limited privilege to access the Content on the Platform. Users shall
                        not copy, adapt, or modify any content without the written permission
                        of the Company.
                    </p>
                    <h2 title="Indemnity">6.Indemnity</h2>
                    <p>
                        Users of this Platform agree to indemnify, defend, and hold harmless
                        the Company/Platform, and their respective directors, officers,
                        employees, and agents (collectively, "Parties"), from and against any
                        losses, liabilities, claims, damages, demands, costs, and expenses
                        (including legal fees and disbursements in connection therewith and
                        interest chargeable thereon) asserted against or incurred by us that
                        arise out of, result from, or may be payable by, any breach or
                        non-performance of any representation, warranty, covenant, or
                        agreement made or obligation to be performed according to these terms
                        of use. Furthermore, the User agrees to hold the Company/Platform
                        harmless against any claims made by any third party due to, or arising
                        out of, or in connection with:
                    </p>
                    <ul>
                        <li>User’s use of the Platform,</li>
                        <li>User’s violation of these Terms and Conditions;</li>
                        <li>User’s violation of any rights of another;</li>
                        <li>User’s alleged improper conduct according to these Terms;</li>
                        <li>User’s conduct in connection with the Platform;</li>
                        <li>
                            The User agrees to fully cooperate in indemnifying the Company and
                            the Platform at the user’s expense. The user also agrees not to
                            settle with any party without the consent of the Company.
                        </li>
                    </ul>
                    <h2 title="Limitation of Liability">7.Limitation of Liability</h2>
                    <p>
                        <b>
                            The Founders/Promoters/Partners/Associated people of the
                            Company/Platform are not responsible
                        </b>{" "}
                        for any consequences arising out of the following events:
                    </p>
                    <ul>
                        <li>
                            If the Platform is inoperative/non-responsive due to any
                            connectivity errors associated with the internet connection such as
                            but not limited to slow connectivity, no connectivity, server
                            failure;
                        </li>
                        <li>
                            If the User has fed incorrect information or data or for any
                            deletion of data;
                        </li>
                        <li>
                            If there is an undue delay or inability to communicate through
                            email;
                        </li>
                        <li>
                            If there is any deficiency or defect in the Services managed by Us;
                        </li>
                        <li>
                            If there is a failure in the functioning of any other service
                            provided by the Platform.
                        </li>
                    </ul>
                    <p>
                        The Platform accepts no liability for any errors or omissions, on
                        behalf of itself, or for any damage caused to the User, the User’s
                        belongings, or to any third party, resulting from the use or misuse of
                        the Platform or any service availed of by the User through the
                        Platform. The service and any Content or material displayed on the
                        service are provided without any guarantees, conditions, or warranties
                        as to its accuracy, suitability, completeness, or reliability. The
                        Platform will not be liable to you for the <b title="unavailability">unavailability</b> or
                        failure of the Platform.
                    </p>
                    <p>
                        Users are to comply with all laws applicable to them or their
                        activities, and with all Policies, which are hereby{" "}
                        <b title="incorporated">incorporated</b>
                        into this <b title="Agreement">Agreement</b> by reference.
                    </p>
                    <p>
                        The <b title="Platform">Platform</b> expressly excludes any liability for any loss or
                        damage that was not reasonably foreseeable by the Platform and which
                        is incurred by you in connection with the Platform, including loss of
                        profits; and any loss or damage incurred by you as a result of your
                        breach of these terms.
                    </p>
                    <p>
                        To the fullest extent <b title="permitted">permitted</b> by law, the Platform shall not
                        be liable to you or any other party for any loss or damage, regardless
                        of the form of action or basis of any claim. You acknowledge and agree
                        that your sole and exclusive remedy for any dispute with us is to
                        terminate your use of the Platform.
                    </p>
                    <h2 title="Term">8.Term</h2>
                    <p>
                        These Terms shall continue to form a valid and binding contract
                        between the Parties and shall continue to be in full force and effect
                        until the User continues to access and use the Platforms.
                    </p>
                    <p>
                        The Users may <b title="terminate">terminate</b> their use of the Platform at any time.
                    </p>
                    <p>
                        The Company may <b title="terminate">terminate</b> these Terms and close any account at
                        any time without notice and/or suspend or terminate a User’s access to
                        the Platform at any time and for any reason, if any <b title="discrepancy ">discrepancy</b>{" "}
                        or legal issue arises.
                    </p>
                    <p>
                        Such <b title="suspension">suspension</b> or termination shall not limit our right to
                        take any other action against you that the Company considers
                        appropriate.
                    </p>
                    <p>
                        It is also hereby declared that the Company may discontinue the
                        Services and Platforms without any prior notice.
                    </p>
                    <h2 title="Termination">9.Termination</h2>
                    <p>
                        The Company reserves the right, in its sole discretion, to
                        unilaterally terminate the User’s access to the Platform, or any
                        portion thereof, at any time, without notice or cause.
                    </p>
                    <p>
                        The Platform also reserves the universal right to deny access to
                        particular users, to any/all of are on its Platform without any prior
                        notice/explanation to protect the interests of the Platform and/or
                        other visitors to the Platform.
                    </p>
                    <p>
                        The Platform reserves the right to limit, deny or create different
                        access to the <b title="Platform">Platform</b> and its features concerning different
                        Users, or to change any of the features or introduce new features
                        without prior notice.
                    </p>
                    <p>
                        The User shall continue to be bound by these Terms, and it is
                        expressly agreed to by the Parties that the User shall not have the
                        right to terminate these Terms till the expiry of the same.
                    </p>
                    <h2 title="Communication">10.Communication</h2>
                    <p>
                        By using this Platform and providing your identity and contact
                        information to the Company through the Platform, you hereby agree and
                        consent to receive calls, emails, or SMS from the Company and/or any
                        of its representatives at any time, but only with your consent.
                    </p>
                    <p>
                        You can report any <b title="discrepancies">discrepancies</b> regarding the Platform or
                        content-related information to "info@vedhasaitech.com," and the Company will
                        take necessary action after conducting an investigation. The response
                        with resolution, if any issues are found, will depend on the time
                        taken for the investigation.
                    </p>
                    <p>
                        You expressly agree that, <b title="notwithstanding">notwithstanding</b> anything contained
                        hereinabove, you may be contacted by the Company or its
                        representatives regarding any services you have availed of on the
                        Platform or anything related to them. You also agree to indemnify the
                        Company from any harassment claims. It is mutually agreed that any
                        information shared by you with the Company shall be governed by the
                        Privacy Policy.
                    </p>
                    <h2 title="User Obligations and Formal Undertakings as to Conduct">11.User Obligations and Formal Undertakings as to Conduct</h2>
                    <p>
                        You acknowledge that you are a restricted user of this Platform and
                        that you:
                    </p>
                    <p>
                        Agree to provide genuine credentials during the registration process
                        on the Platform. You shall not use a fictitious identity to register.
                        The Company is not liable if you have provided incorrect information.
                    </p>
                    <p>
                        Agree to ensure that your Name, Email address, Address, Mobile number,
                        and any other information provided during account registration are
                        valid at all times, and you shall keep your information accurate and
                        up-to-date.
                    </p>
                    <p>
                        Are solely responsible for <b title="maintaining">maintaining</b> the{" "}
                        <b title="confidentiality">confidentiality</b> of your account password. You agree to notify
                        the Company immediately of any unauthorized use of your account. The
                        Company reserves the right to close your account at any time for any
                        reason or no reason.
                    </p>
                    <p>
                        Understand and acknowledge that the data submitted is manually entered
                        into the Platform's database for easy reference and to streamline the
                        Platform's services.
                    </p>
                    <p>
                        Authorize the Platform to use, store, or otherwise process certain
                        personal information and all published Content, Client responses,
                        Client locations, User comments, reviews, and ratings for
                        personalization of Services, marketing and promotional purposes, and
                        optimization of <b title="User-related">User-related</b> options and Services.
                    </p>
                    <p>
                        Understand and agree that, to the fullest extent permissible by law,
                        the Platform/Company and their successors, assigns, or affiliates
                        shall not be liable for any loss or damage, direct or indirect, in
                        connection with or arising from the use of the Platform or these terms
                        of use.
                    </p>
                    <p>
                        Agree not to cut, copy, modify, recreate, reverse engineer,
                        distribute, disseminate, post, publish, or create derivative works
                        from, transfer, or sell any information obtained from the Platform
                        without the prior express written permission of the Company.
                    </p>
                    <p>
                        Agree not to access the Platform and/or the materials or Services by
                        any means other than through the provided interface. Using automated
                        devices or methodologies to access, acquire, copy, or monitor any
                        portion of the Platform or its content is prohibited.
                    </p>
                    <p>
                        Agree that the Content generated by Users and displayed on the
                        Platform is not owned by the Company, and the Company is not
                        responsible for the content. You may report offensive or
                        <b title="objectionable content">objectionable content</b>, which the Company may remove at its
                        discretion.
                    </p>
                    <p>
                        Agree to follow the terms, conditions, and policies of the Vendor
                        affiliated with the Company when utilizing their services.
                    </p>
                    <h2 title="Furthermore, you undertake not to">12.Furthermore, you undertake not to:</h2>
                    <p>
                        Engage in any activity that interferes with or disrupts access to
                        <b title="the Platform or the Services"> the Platform or the Services.</b>
                    </p>
                    <p>
                        Impersonate any person or entity, or misrepresent your affiliation
                        with a person or entity.
                    </p>
                    <p>
                        Probe, scan, or test the vulnerability of the Platform or any network
                        connected to it, or breach its security measures.
                    </p>
                    <p>
                        Disrupt or interfere with the security of the Platform or cause harm
                        to it or other users.
                    </p>
                    <p>Use the Platform or any content therein for unlawful purposes.</p>
                    <p>
                        Make bulk purchases for resale activities, as the Company reserves the
                        right to cancel such orders and block the concerned User account.
                    </p>
                    <p>
                        Provide false information, as the Company may reject registration and
                        debar you from using the Services if false information is detected.
                    </p>
                    <p>
                        Post defamatory, offensive, obscene, indecent, or abusive material on
                        the website.
                    </p>
                    <h2 title="Violate any applicable laws, rules, or regulations">13.Violate any applicable laws, rules, or regulations.</h2>
                    <p>
                        Commit any acts that may disrupt the Company's Services or violate
                        these Terms.
                    </p>
                    <p>
                        Additionally, you expressly authorize the <b title="Company/Platform">Company/Platform</b> to
                        disclose your information to law enforcement or government officials
                        if deemed necessary for the investigation of crimes or to satisfy
                        legal requirements.
                    </p>
                    <p>
                        By indicating your acceptance to purchase any service offered on the
                        site, you are obligated to complete such transactions after making
                        payment. Users shall prohibit from indicating their acceptance to
                        avail services where the transactions have remained incomplete.
                    </p>
                    <p>
                        You agree to use the services provided by the Company and its
                        affiliates for lawful purposes only.
                    </p>
                    <p>
                        The Company reserves the right to suspend or terminate your access and
                        activity on the Platform for various reasons, including breaches of
                        these Terms, provision of false information, actions causing harm to
                        others or the Company, misuse of the platform, or sharing platform
                        credentials for abuse or commercial exploitation.
                    </p>
                    <h2 title="Refund and Cancellation Policies">14.Refund and Cancellation Policies</h2>
                    <h4 title="Subscription-Based Services">Subscription-Based Services</h4>
                    <p>
                        Certain aspects of our Service are offered on a subscription basis
                        (referred to as "Subscriptions"). Users will be charged in advance on
                        a recurring and periodic basis (known as a "Billing Cycle"). The
                        length of the Billing Cycle depends on the subscription plan selected
                        during the purchase process and can be either monthly or annual.
                    </p>
                    <h4 title="Automatic Renewal">Automatic Renewal</h4>
                    <p>
                        At the conclusion of each Billing Cycle, your Subscription will
                        automatically renew under the same terms and conditions unless you
                        choose to cancel or Vedhas AI terminates the Subscription.
                        Cancellation of Subscription renewal can be done through you or by
                        reaching out to the Vedhas AI support team.
                    </p>
                    <h4 title="Payment Information">Payment Information</h4>
                    <p>
                        To process the payment for your Subscription, a valid payment method,
                        such as a credit card or PayPal account, is necessary. You are
                        required to provide Vedhas AI with precise and complete billing
                        information, including your full name, address, state, zip code,
                        telephone number, and valid payment method details. By providing this
                        payment information, you expressly authorize Vedhas AI to charge
                        all Subscription fees incurred through your account to the designated
                        payment method.
                    </p>
                    <h4 title="Billing Failures">Billing Failures</h4>
                    <p>
                        In the event that automatic billing is unsuccessful for any reason,
                        Vedhas AI will generate an electronic invoice specifying that you
                        must manually complete the full payment for the billing period
                        indicated on the invoice within a specified deadline date.
                    </p>
                    <h4 title="Free Trial">Free Trial</h4>
                    <p>
                        Our company, at its sole discretion, may offer a subscription with a
                        free trial for a limited period and limited features. When signing up
                        for the free trial, you may be required to provide your billing
                        information or other information. We reserve the right to modify the
                        terms of service or cancel the free trial offer at any time without
                        prior notice.
                    </p>
                    <h4 title="Subscription Fee Changes">Subscription Fee Changes</h4>
                    <p>
                        We have the right to modify subscription fees for our services at any
                        time and at our sole discretion. Any changes to the subscription fee
                        will take effect at the end of the current billing cycle or on the
                        same day. By continuing to use our service after the subscription fee
                        change comes into effect, you agree to pay the modified amount.
                    </p>
                    <h4 title="Refunds">Refunds</h4>
                    <p>
                        All purchase are final and non-refundable. We do not offer refunds,
                        exchanges, or credits for any services purchased from our platform.
                    </p>
                    <p>
                        Please ensure that you carefully review your purchase before
                        completing the transaction. It is your responsibility to ensure that
                        the service meets your needs and that you have read and understood the
                        terms of service, and any other relevant information provided.
                    </p>
                    <p>
                        We reserve the right to make exceptions to this policy on a
                        case-by-case basis, at our sole discretion. Any exceptions will be
                        communicated clearly in writing.
                    </p>
                    <p>
                        By completing a purchase with our platform, you acknowledge that you
                        have read, understood, and agree to be bound by this No Refund Policy.
                    </p>
                    <h4 title="Cancellation">Cancellation</h4>
                    <ul>
                        <li>
                            Cancellation: You have the right to cancel your subscription at any
                            time. To cancel, please navigate to the "Account Settings" page
                            within the service and follow the provided instructions for
                            cancellation.
                        </li>
                        <li>
                            Access after Cancellation: Upon cancellation, you will continue to
                            have access to the service and its content until the end of your
                            current billing period. After the billing period concludes, your
                            access to the service will be terminated.
                        </li>
                        <li>
                            Refunds: To the extent permitted by applicable law, all payments
                            made for subscriptions are non-refundable. We do not provide
                            refunds, credits, or prorated billing for any partial subscription
                            periods or unused content.
                        </li>
                        <li>
                            Billing Cycle: Your subscription will not be renewed after you
                            cancel. You will not be charged for any future billing cycles after
                            your cancellation takes effect.
                        </li>
                    </ul>
                    <h4 title="Restricted Activities">Restricted Activities</h4>
                    <p>
                        The Service may only be used for purposes that comply with all
                        relevant laws and regulations. By using the Service, you agree to
                        refrain from:
                    </p>
                    <ul>
                        <li>
                            Violating any applicable laws or regulations, whether national or
                            international in scope.
                        </li>
                        <li>
                            Exploiting, harming, or attempting to exploit or harm minors by
                            exposing them to inappropriate content or through any other means.
                        </li>
                        <li>
                            Sending or facilitating the transmission of unsolicited advertising,
                            promotional materials, junk mail, chain letters, spam, or similar
                            solicitations.
                        </li>
                        <li>
                            Impersonating or attempting to impersonate the Company, its
                            employees, other users, or any other individuals or entities.
                        </li>
                        <li>
                            Infringing upon the rights of others or engaging in any illegal,
                            threatening, fraudulent, harmful, or unlawful activities.
                        </li>
                        <li>
                            Engaging in conduct that restricts or inhibits others' use or
                            enjoyment of the Service or that may cause harm or offense to the
                            Company, its users, or expose them to liability.
                        </li>
                        <li>
                            Generating sexual, religious, or political content, as prohibited.
                            Repeated attempts will lead to subscription cancellation and
                            permanent debarment from using our services, with no refunds issued.
                        </li>
                    </ul>
                    <h4 title="Furthermore, you agree not to">Furthermore, you agree not to:</h4>
                    <ul>
                        <li>
                            Use the Service in a manner that could disable, overburden, damage,
                            or impair the Service or interfere with others' use of the Service,
                            including their ability to engage in real-time activities.
                        </li>
                        <li>
                            Use automated devices, processes, or means to access the Service for
                            any purpose, including monitoring or copying any material on the
                            Service.
                        </li>
                        <li>
                            Use manual processes to monitor or copy any material on the Service
                            for unauthorized purposes without prior written consent.
                        </li>
                        <li>
                            Use devices, software, or routines that interfere with the proper
                            functioning of the Service.
                        </li>
                        <li>
                            Introduce viruses, trojan horses, worms, logic bombs, or other
                            malicious or technologically harmful material.
                        </li>
                        <li>
                            Attempt to gain unauthorized access to, interfere with, damage, or
                            disrupt any parts of the Service, its servers, or any connected
                            servers, computers, or databases.
                        </li>
                        <li>
                            Launch denial-of-service or distributed denial-of-service attacks
                            against the Service.
                        </li>
                        <li>
                            Take actions that may damage or falsify the Company's ratings.
                        </li>
                        <li>
                            Otherwise attempt to interfere with the proper operation of the
                            Service.
                        </li>
                    </ul>
                    <h4 title="Account Suspension and Termination">Account Suspension and Termination</h4>
                    <p>
                        We reserve the right to suspend or terminate your account and prohibit
                        access to our services at any time, without prior notification or
                        liability, at our sole discretion. This may occur for any reason,
                        including but not limited to a violation of our Terms of Service. If
                        you wish to close your account, you may do so by simply discontinuing
                        the use of our services.
                    </p>
                    <h4 title="Modifications to Services">Modifications to Services</h4>
                    <p>
                        We have the right to modify, update, or remove any part of our
                        services, including any content or features, at our sole discretion
                        without prior notice. We will not be held responsible if, for any
                        reason, all or any part of our services are unavailable at any time or
                        for any period. Occasionally, we may restrict access to certain parts
                        of our services, or the entire service, to users, including registered
                        users.
                    </p>
                    <h4 title="Amendments to Terms of Service and Privacy Policy">Amendments to Terms of Service and Privacy Policy</h4>
                    <p>
                        We reserve the right to amend our Terms of Service or Privacy Policy
                        at any time by posting the updated terms on our website. It is your
                        responsibility to review the Terms of Service or Privacy Policy
                        periodically. Your continued use of our services following the posting
                        of revised Terms of Service or Privacy Policy indicates your
                        acceptance and agreement to the changes. You are expected to check
                        these pages regularly to stay informed of any changes, as they are
                        binding on you. By continuing to access or use our services after any
                        revisions become effective, you agree to be bound by the revised
                        terms. If you do not agree to the updated Terms of Service or Privacy
                        Policy, you are no longer authorized to use our services.
                    </p>
                    <h4 title="Acknowledgment">Acknowledgment</h4>
                    <p>
                        By using our services, you acknowledge that you have read these Terms
                        of Service and Privacy Policy and agree to be bound by them.
                    </p>
                    <h2 title="Intellectual Property Rights">15.Intellectual Property Rights</h2>
                    <p>
                        Unless expressly agreed to in writing, nothing contained herein shall
                        give the User a right to use any of the <b title="Platform’s">Platform’s</b> trade names,
                        trademarks, service marks, logos, domain names, information,
                        questions, answers, solutions, reports, and other distinctive brand
                        features, save according to the provisions of these Terms. All logos,
                        trademarks, brand names, service marks, domain names, including
                        material, designs, and graphics created by and developed by the
                        Platform and other distinctive brand features of the Platform are the
                        property of the Company or the respective copyright or trademark
                        owner. Furthermore, concerning the Platform created by the Company,
                        the Company shall be the exclusive owner of all the designs, graphics,
                        and the like, related to the Platform.
                    </p>
                    <p>
                        The User agrees that any kind of information, resources, activities,
                        recommendations obtained/availed from the Platform, written or oral,
                        will not create any warranty and the Platform disclaims all
                        liabilities resulting from these.
                    </p>
                    <p>
                        The User is aware all <b title="intellectual property">intellectual property</b>, including but not
                        limited to copyrights, relating to said services resides with the
                        owners, and that at no point does any such intellectual property stand
                        transferred from the aforementioned creators to the Company or any
                        other User. The User is aware that the Company merely provides a
                        platform through which the Users can communicate and schedule
                        meetings, and the Company/Platform does not own any of the
                        intellectual property relating to the independent content displayed on
                        the Platform, apart from created graphics and specified content.
                    </p>
                    <p>
                        The User is further aware that any reproduction or infringement of the
                        intellectual property of the aforementioned owners by the User will
                        result in legal action being initiated against the User by the
                        respective owners of the intellectual property so reproduced/infringed
                        upon. It is agreed to by the Parties that the contents of this Section
                        shall survive even after the termination or expiry of the Terms and/or
                        Policy.
                    </p>
                    <h2 title="Disclaimer of Warranties and Liabilities">16.Disclaimer of Warranties and Liabilities</h2>
                    <p>
                        The User agrees and undertakes that they are accessing the Platform at
                        their sole risk and that they are using their best and prudent
                        judgment before availing any service listed on the Platform or{" "}
                        <b title="accessing/using any information displayed thereon">accessing/using any information displayed thereon.</b>
                    </p>
                    <p>
                        The User agrees that any kind of information, resources, activities,
                        recommendations obtained/availed from the Platform, written or oral,
                        will not create any warranty and the Platform disclaims all
                        liabilities resulting from these.
                    </p>
                    <p>
                        The Platform does not guarantee that the Services contained in the
                        Platform will be uninterrupted or error-free, or that the Platform or
                        its server will be free of viruses or other harmful components, and
                        the User hereby expressly accepts any associated risks involved with
                        the User’s use of the Platform.
                    </p>
                    <p>
                        It is further agreed to by the Parties that the contents of this
                        Section shall survive even after the termination or expiry of the
                        <b title="Terms and/or Policy">Terms and/or Policy.</b>
                    </p>
                    <h2 title="Force Majeure">17.Force Majeure</h2>
                    <p>
                        Neither the Company nor the Platform shall be liable for damages for
                        any delay or failure to perform its obligations hereunder if such
                        delay or failure is due to a cause beyond its control or without its
                        fault or negligence, due to Force Majeure events including but not
                        limited to acts of war, acts of God, earthquake, riot, fire, festive
                        activities sabotage, labor shortage or dispute, internet interruption,
                        technical failure, breakage of sea cable, hacking, piracy, cheating,
                        illegal or unauthorized.
                    </p>
                    <h2 title="Dispute Resolution and Jurisdiction">18.Dispute Resolution and Jurisdiction</h2>
                    <p>
                        It is expressly agreed to by the Parties hereto that the formation,
                        interpretation, and performance of these Terms and any disputes
                        arising therefrom will be resolved through a two-step{" "}
                        <b title="Alternate Dispute Resolution (“ADR”) mechanism">Alternate Dispute Resolution (“ADR”) mechanism.</b> It is further
                        agreed to by the Parties that the contents of this Section shall
                        survive even after the termination or expiry of the Terms and/or
                        Policy.
                    </p>
                    <p>
                        <b title="Mediation">Mediation:</b> In case of any dispute between the parties, the Parties
                        will attempt to resolve the same amicably amongst themselves, to the
                        mutual satisfaction of all parties. In the event that the Parties are
                        unable to reach such an amicable solution within thirty (30) days of
                        one Party communicating the existence of a dispute to any other Party,
                        the dispute will be resolved by arbitration, as detailed hereinbelow;
                    </p>
                    <p>
                        <b title="Arbitration">Arbitration</b> : In the event that the Parties are unable to
                        amicably resolve a dispute by mediation, said dispute will be referred
                        to arbitration by a sole arbitrator to be appointed by the Company,
                        and the award passed by such sole arbitrator will be valid and binding
                        on all parties. The Parties shall bear their own costs for the
                        proceedings, although the sole arbitrator may, in his/her sole
                        discretion, direct either Party to bear the entire cost of the
                        proceedings. The arbitration shall be conducted in English, and the
                        seat of Arbitration shall be the city of Haryana, India.
                    </p>
                    <p>
                        The Parties expressly agree that the Terms, Policy, and any other
                        agreements entered into between the Parties are{" "}
                        <b title="governed by the laws, rules, and regulations of India">governed by the laws, rules, and regulations of India.</b>
                    </p>
                    <h2 title="Notices/Grievances">17.Notices/Grievances</h2>
                    <p>
                        Any and all communication relating to any dispute or grievance
                        experienced by the User may be communicated to the Company by the User
                        by emailing to <b title="info@vedhasaitech.com">info@vedhasaitech.com</b>
                    </p>
                    <h2 title="Miscellaneous Provisions">18.Miscellaneous Provisions</h2>
                    <p>
                        <b title="Entire Agreement">Entire Agreement</b> : These Terms, read with the Policy, form the
                        complete and final contract between the User and the Company with
                        respect to the subject matter hereof and supersedes all other
                        communications, representations, and agreements (whether oral,
                        written, or otherwise) relating thereto.
                    </p>
                    <p>
                        <b title="Waiver">Waiver : </b>The failure of either Party at any time to
                        require performance of any provision of these Terms shall in no manner
                        affect such Party's right at a later time to enforce the same. No
                        waiver by either party of any breach of these Terms, whether by
                        conduct or otherwise, in any one or more instances, shall be deemed to
                        be or construed as a further or continuing waiver of any such breach,
                        or a waiver of any other breach of these Terms.
                    </p>
                    <p>
                        <b title="Severability">Severability </b>: If any provision/clause of these Terms is held
                        to be invalid, illegal, or unenforceable by any court or authority of
                        competent jurisdiction, the validity, legality, and enforceability of
                        the remaining provisions/clauses of these Terms shall in no way be
                        affected or impaired thereby, and each such provision/clause of these
                        Terms shall be valid and enforceable to the fullest extent permitted
                        by law. In such case, these Terms shall be reformed to the minimum
                        extent necessary to correct any invalidity, illegality, or
                        unenforceability, while preserving to the maximum extent the original
                        rights, intentions, and commercial expectations of the Parties hereto,
                        as expressed herein.
                    </p>
                    <h2 title="➤ Contact Us">➤ Contact Us</h2>
                    <p>
                        If you have any questions about this Policy or any other policy, the
                        practices of the Platform, or your experience, you can contact us at{" "}
                        <b title="info@vedhasaitech.com">info@vedhasaitech.com</b> or on the address mentioned below.
                    </p>
                    <div className="TredMark">
                        <b title="From Vedhas AI Team">From Vedhas AI Team.</b>
                        <div className="TredMark_logo">
                            <Link to={'/'}>
                                <img
                                    src={CompanyLogo}
                                    title="Vedhas AI"
                                    alt="Vedhas AI"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
