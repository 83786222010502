import React from "react";
import BlogHero from "../components/BlogComponent/blogHerobanner";
import BlogPost from "../components/BlogComponent/blogPost";
import Popular from "../components/BlogComponent/popularRes";
import { Helmet } from "react-helmet-async";
export const Blog = () => {
  return (
    <>
      <Helmet>
        <title>AI Insights: Your Source for AI Knowledge and Trends</title>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="AI Insights is your go-to source for the latest knowledge and trends in artificial intelligence. We provide expert analysis, industry updates, and practical insights to help you stay informed and make informed decisions in the rapidly evolving AI landscape." />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="768" />
        <meta property="og:title" content="AI Insights: Your Source for AI Knowledge and Trends" />
        <meta property="og:description" content="AI Insights is your go-to source for the latest knowledge and trends in artificial intelligence. We provide expert analysis, industry updates, and practical insights to help you stay informed and make informed decisions in the rapidly evolving AI landscape." />
        <meta property="og:site_name" content="vedhasaitech.com/blog" />
        <meta property="og:url" content="https://vedhasaitech.com/blog" />
        <meta property="og:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta property="og:image:alt" content="Ai Technology" />
        <meta property="og:image:secure_url"
          content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:url" href="https://vedhasaitech.com/blog" />
        <meta name="twitter:title" content="AI Insights: Your Source for AI Knowledge and Trends" />
        <meta name="twitter:description" content="AI Insights is your go-to source for the latest knowledge and trends in artificial intelligence. We provide expert analysis, industry updates, and practical insights to help you stay informed and make informed decisions in the rapidly evolving AI landscape." />
        <meta name="twitter:image" content="https://vedhasaitech.com/static/media/Asset%201%201.36d9e25c2d2d3e1057c3.png" />
        <meta name="twitter:image:alt" content="Ai Technology" />
        <link rel="canonical" href="https://vedhasaitech.com/blog" />
      </Helmet>
      <BlogHero />
      <BlogPost />
      <Popular />
    </>
  );
};
