import React from "react";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import "./commonHead.scss";
import IconHome from "../../assets/images/9104258_home_house_homepage_icon 1.svg";

export default function CommonHead({ Heading, Sub }) {
  return (
    <section className="Common_Head">
      <div className="main-heading">
        <div className="blur"></div>
        <Fade bottom duration={600} delay={400}>
          <h1 title={Heading}>{Heading}</h1>
        </Fade>
        <div className="flex-main">
          <Fade bottom duration={700} delay={500}>
            <img src={IconHome} alt="HomeIcon" />

            <div className="text-home">
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "white",
                })}
              >
                <p title="Home">Home</p>
              </NavLink>
            </div>
          </Fade>
          <div className="text-blog">
            <Fade bottom duration={700} delay={500}>
              <p title={Sub}>{Sub}</p>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}
