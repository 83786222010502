import React from 'react'
import { createBrowserRouter } from "react-router-dom";
import { DefaultLayout } from './DefaultLayout ';
import Home from './Home';
import { Blog } from './Blog';
import { BlogDetails } from './BlogDetails';
import { Services } from './Services';
import { MobileApp } from './MobileApp';
import { Contact } from './Contact';
import { About } from './About';
import { Product } from './Product';
import Tearms_Condition from './TearmsCondition';
import Disclaimer from './disclaimer';
import PrivacyPolicyComponet from './privacyPolicy';
const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        path: "/",
        element: <Home />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blogdetails",
        element: <BlogDetails />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/mobileapp",
        element: <MobileApp />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/product",
        element: <Product />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyComponet />
      },
      {
        path: "/terms-and-conditions",
        element: <Tearms_Condition />
      },
      {
        path: '/disclaimer',
        element: <Disclaimer />
      }
    ],
  },
]);
export default router;
