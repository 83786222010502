import React from "react";
import "./popular.scss";
import PopRes from "../../../assets/images/Rectangle pop (1).svg";
import Rectangle1 from "../../../assets/images/Rectangle pop1 (1).svg";
import Rectangle2 from "../../../assets/images/Rectangle pop2 (1).svg";
import Rectangle3 from "../../../assets/images/Rectangle pop3 (1).svg";
import { NavLink } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
export default function Popular() {
  const Popular_Res = [
    {
      image: Rectangle1,
      description:
        "Boost Your Small Business With Expert Website Development And SEO Services.",
      read_more: "Read More",
    },
    {
      image: Rectangle2,
      description:
        "IoT Application Development Guide for Boosting Efficiency & Growth.",
      read_more: "Read More",
    },
    {
      image: Rectangle3,
      description:
        "CodeCrafters: Your Guide to Unleashing Success with Open Source Consulting Services.",
      read_more: "Read More",
    },
  ];
  return (
    <section className="popular_resources">
      <div className="container">
        <h1 className="popular_res_heading" title="Popular Resources">
          Popular Resources
        </h1>
        <div className="popular-grid">
          <div className="popular-sub-grid">
            {Popular_Res &&
              Popular_Res?.map((el, i) => {
                return (
                  <div className="popular-flex" title={el?.description} key={i}>
                    <div className="popular-image">
                      <Zoom>
                        <img src={el?.image} alt="Blog Card" />
                      </Zoom>
                    </div>
                    <div className="popular-text">
                      <Fade bottom duration={600} delay={400}>
                        <p>{el?.description}</p>
                      </Fade>
                      <NavLink to="/blogdetails">
                        <Fade bottom duration={700} delay={500}>
                          <h3 title={el?.read_more}>{el?.read_more}</h3>
                        </Fade>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="popular-sub-grid-img">
            <Zoom>
              <img src={PopRes} title="Blog Card" alt="Blog Card" />
            </Zoom>
          </div>
        </div>
      </div>
    </section>
  );
}
