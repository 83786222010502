import React from 'react'
import Herobanner from '../components/HomeComponent/herobanner'
import Service from '../components/HomeComponent/services'
import Finestwork from '../components/HomeComponent/finestwork'
import Technology from '../components/HomeComponent/technology'
import Project from '../components/HomeComponent/project'
import OurTechnology from '../components/HomeComponent/OurTechnology'
import Experience from '../components/HomeComponent/Experience'
import FAQs from '../components/HomeComponent/FAQs'
import Testimonials from '../components/HomeComponent/Testimonials'
export default function Home() {
    return (
        <>
            <Herobanner />
            <Service />
            <Finestwork />
            <Technology />
            <OurTechnology />
            <Experience />
            <Testimonials/>
            <FAQs/>
            <Project />
        </>
    )
}