import React from "react";
import CompanyLogo from "../../assets/VEDHAS AI/02 (2).png";
import { Link } from "react-router-dom";

export default function DisClaimer() {
    return (
        <div className="TermsAndConditions_PrivacyPolicy_Section">
            <div className="container">
                <h1 title="Disclaimer">Disclaimer</h1>
                <div className="TermsAndConditions_PrivacyPolicy_info">
                    <p>
                        <a rel='noopener noreferrer' title="Vedhas" target="_blank" href="https://vedhasaitech.com/">
                            Vedhas
                        </a>{" "}
                        is an AI-based contemporary tool that is created to help those who
                        need to produce academic materials and assignments. You are hereby
                        requested to carefully read the following disclaimer:
                    </p>
                    <ul>
                        <li>
                            <b title="Educational Purposes">Educational Purposes:</b>Vedhas is to be used for
                            educational purposes only. It was developed to guide you in
                            composing academic content and provide you with necessary
                            assistance. You should advise on the reasonable use and ethical
                            aspects of the user generated content.
                        </li>
                        <li>
                            <b title="Accuracy and Completeness">Accuracy and Completeness:</b>Despite Vedhas
                            aspiration towards precision and scope, we can’t warrant you its
                            accuracy, trustworthiness or wholeness along with the
                            model-created text. Users must double-check and add more facts
                            where necessary.
                        </li>
                        <li>
                            <b title="Academic Integrity">Academic Integrity:</b>You are obliged to observe the
                            principles of academic integrity as well as your institution’s
                            regulations on plagiarism and honesty at all times. Vedhas
                            is not responsible for any misuse-related academic dishonesty or
                            violations within it whatsoever.
                        </li>
                        <li>
                            <b title="Data Privacy">Data Privacy:</b> The security of our users’ privacy and their
                            data security matters most to us; nevertheless, no system is
                            absolutely safe. Nevertheless, you accept that when using
                            Vedhas you may be exposed to privacy risks associated with
                            digital tools usage or/and data transmission via internet.
                        </li>
                        <li>
                            <b title="Third-Party Content">Third-Party Content: </b>There might be cases where third-party
                            content or services interact with Vedhas. We do not
                            recommend or take any liability on the third-party content,
                            products, or services you can reach via Vedhas.
                        </li>
                        <li>
                            <b title="Limitation of Liability">Limitation of Liability:</b>Vedhas and its developers
                            will not be liable for any direct, indirect, incidental, special,
                            consequential or punitive damages including those arising from the
                            use or inability to use the application. This includes but is not
                            limited to loss of profits, data or other intangible losses.
                        </li>
                        <li>
                            <b title="Changes and Updates">Changes and Updates:</b>We hold the right to change or update
                            the application and this disclaimer at anytime without prior
                            notice. It is your duty to periodically review this disclaimer for
                            any changes.
                        </li>
                    </ul>
                    <p>
                        You agree with this disclaimer and accept that you are fully
                        responsible for your actions and decisions when using Vedhas
                        system.
                    </p>
                </div>
                <div className="TredMark">
                    <b title="From Vedhas AI Team">From Vedhas AI Team.</b>
                    <div className="TredMark_logo">
                        <Link to={"/"}>
                            <img
                                src={CompanyLogo}
                                title="Vedhas AI"
                                alt="Vedhas AI"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
