import React, { useEffect, useRef, useState } from 'react'
import './faq.scss';

export default function FAQs() {
    const [dropdown, setDropdown] = useState(false);
    const FAQs = [
        {
            question: "What services does Vedhas AI offer?",
            answer: " Vedhas AI specializes in a range of AI-based solutions tailored for businesses. Our services include AI-powered application development, machine learning solutions, data analytics, automation tools, and custom software development. We also offer consultation on integrating AI technology into existing business operations to enhance efficiency and productivity."
        },
        {
            question: "What industries does Vedhas AI serve?",
            answer: "We cater to a variety of industries, including education, healthcare, finance, retail, and technology. Our AI-driven solutions are adaptable to any sector looking to innovate and streamline processes, improve customer experiences, and make data-driven decisions."
        },
        {
            question: "What makes Vedhas AI different from other IT service providers?",
            answer: " Vedhas AI combines cutting-edge AI technology with innovative problem-solving to deliver superior results. We focus on personalized solutions tailored to your business needs, ensuring scalability, efficiency, and long-term value. Our collaborative approach and commitment to staying at the forefront of AI advancements set us apart."
        },
        {
            question: "How do I get started with Vedhas AI?",
            answer: "Getting started is easy! Simply contact us through our website or schedule a consultation to discuss your project needs. We’ll work together to understand your goals and develop a customized AI solution that drives success for your business."
        }
    ]
    const answerRef = useRef([]);

    const handleOnclick = (index) => {
        setDropdown(dropdown === index ? false : index);
    };
    useEffect(() => {
        if (dropdown !== false) {
            answerRef.current[
                dropdown
            ].style.height = `${answerRef.current[dropdown].scrollHeight}px`;
        }
    }, [dropdown]);
    return (
        <div className='FAQs'>
            <div className="container-md">
                <div className="FAQs-Title">
                    <h2 title='Frequently Asked Questions'>Frequently Asked Questions</h2>
                </div>
                <div className="FAQs-Desc">
                    <p title="Welcome to our FAQ section! Here, we've compiled a comprehensive list of questions and answers to help you better understand our services and the value we offer.">Welcome to our FAQ section! Here, we've compiled a comprehensive list of questions and answers to help you better understand our services and the value we offer. </p>
                </div>
                {
                    FAQs?.map((el, index) => {
                        return (
                            <div onClick={() => handleOnclick(index)} key={index} title={el?.question} className="FAQs_Main">
                                <div className="FAQs_Question">
                                    <div className="FAQ_ALigment">
                                        <div className="title_cover">
                                            <p>{el?.question}</p>
                                        </div>
                                        <div className="icon">
                                            <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                height="20px"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                {dropdown === index ? (
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                ) : (
                                                    <>
                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                    </>
                                                )}
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`FAQ_Answer ${dropdown === index ? "open" : ""
                                        }`}
                                    ref={(el) => (answerRef.current[index] = el)}
                                    style={{
                                        height:
                                            dropdown === index
                                                ? `${answerRef.current[index]?.scrollHeight}px`
                                                : "0",
                                    }}
                                >
                                    {dropdown === index && <span>{el?.answer}</span>}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
