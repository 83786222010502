import React, { useEffect, useState } from "react";
import "./header.scss";
import CompanyLogo from "../../assets/VEDHAS AI/01__1_-removebg-preview.png";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
    document.body.classList.remove("cm-overflow");
  };

  const toggleMenu = () => {
    setOpen(!open);
    if (!open) {
      document.body.classList.add("cm-overflow");
    } else {
      document.body.classList.remove("cm-overflow");
    }
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 15) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${scroll ? "scroll" : ""}`}>
      <div className="container">
        <div className="header-grid">
          <div className="header-logo">
            <Link to="/" onClick={handleOnClose}>
              <img src={CompanyLogo} title="Vedhas AI Technologies" alt="Vedhas AI Technologies" />
            </Link>
          </div>
          <nav className="header-menu">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#c36bd6" : "",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                textDecoration: "none",
              })}
              title="Home"
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              style={({ isActive }) => ({
                color: isActive ? "#c36bd6" : "",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                textDecoration: "none",
              })}
              title="Services"
            >
              Services
            </NavLink>
            <NavLink
              to="/product"
              style={({ isActive }) => ({
                color: isActive ? "#c36bd6" : "",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                textDecoration: "none",
              })}
              title="Product"
            >
              Product
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "#c36bd6" : "",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                textDecoration: "none",
              })}
              title="About"
            >
              About
            </NavLink>
            <NavLink
              to="/blog"
              style={({ isActive }) => ({
                color:
                  isActive || window.location.pathname.startsWith("/blog")
                    ? "#c36bd6"
                    : "",
                background:
                  isActive || window.location.pathname.startsWith("/blog")
                    ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                    : "",
                textDecoration: "none",
              })}
              title="Blogs"
            >
              Blogs
            </NavLink>
          </nav>
          <div className="hamburger" onClick={toggleMenu}>
            <svg
              stroke="#c36bd6"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              ></path>
            </svg>
          </div>
          <div className="login-button">
            <button title="Contact Us">
              <Link to={"/contact"}>Contact Us</Link>
            </button>
          </div>
        </div>
        <div
          className={`mobile_menu ${open ? "open" : ""} ${scroll ? "set_top" : ""
            }`}
        >
          <div className="menu_item">
            <NavLink
              to={"/"}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                borderRadius: '20px'

              })}
              onClick={handleOnClose}
              title="Home"
            >
              <p>Home</p>
            </NavLink>
            <NavLink
              to={"/services"}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                borderRadius: '20px'

              })}
              onClick={handleOnClose}
              title="Services"
            >
              <p>Services</p>
            </NavLink>
            <NavLink
              to={"/product"}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                borderRadius: '20px'
              })}
              onClick={handleOnClose}
              title="Product"
            >
              <p>Product</p>
            </NavLink>
            <NavLink
              to={"/about"}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                borderRadius: '20px'

              })}
              onClick={handleOnClose}
              title="About"
            >
              <p>About</p>
            </NavLink>
            <NavLink
              to={"/blog"}
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "white",
                background: isActive
                  ? "linear-gradient(110.61deg, rgba(195, 107, 214, 0.2) -10.33%, rgba(88, 63, 187, 0.3) 120.46%)"
                  : "",
                borderRadius: '20px'
              })}
              onClick={handleOnClose}
              title="Blogs"
            >
              <p>Blogs</p>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
}
