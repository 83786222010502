import React from "react";
import "./herobanner.scss";
import { Fade, Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import HerobannerImage from "../../../assets/images/icons/Asset 1 1.png";
export default function Herobanner() {
  return (
    <div className="hero_banner_bg">
      <div className="container">
        <div className="hero-banner-main-grid">
          <div className="sub-grid-text">
            <Fade bottom duration={600} delay={400}>
              <h1 title="Transform Your Business with AI Solutions">
                Transform Your Business with
                <span> AI Solutions</span>
              </h1>
            </Fade>
            <Fade bottom duration={700} delay={500}>
              <p
                title="At Vedhas AI, we are passionate about creative problem-solving,
              innovative thinking, and pushing the boundaries of what's possible
              for brands. With every client, we are committed to delivering
              forward-thinking solutions that drive success in the digital age.
              We also pride ourselves on creating superior products using
              cutting-edge technology."
              >
                At Vedhas AI, we are passionate about creative problem-solving,
                innovative thinking, and pushing the boundaries of what's possible
                for brands. With every client, we are committed to delivering
                forward-thinking solutions that drive success in the digital age.
                We also pride ourselves on creating superior products using
                cutting-edge technology.
              </p>
            </Fade>
            <Fade bottom duration={800} delay={600}>
              <Link to={"/contact"}>
                <button title="Start a Conversation">
                  Start a Conversation
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    ></path>
                  </svg>
                </button>
              </Link>
            </Fade>
          </div>
          <Zoom>
            <div className="sub-grid-image">
              <img
                title="Empower Your Ideas with AI Collaboration"
                src={HerobannerImage}
                alt="herobanner"
              />
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
}
